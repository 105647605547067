import React,{Component} from 'react';
import axios from 'axios';
import {redirectForgotPasswordUrl,forgotPassword} from '../url'


export default class ForgotPassword extends Component{
    constructor(props) {
        super(props);

        this.state = {
            email:null,
            redirect_url:redirectForgotPasswordUrl,
            message:null,
            error:null,
            loading:false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {

        this.setState({
            [event.target.name]: event.target.value,
        });

    }
    handleSubmit(event) {
        event.preventDefault();

        this.setState({
            ...this.state,
            loading:true
        })

        axios.post(forgotPassword,this.state)
        .then((response) => {
            const data = response["data"];
            if(data.success===true)
            {
                this.setState({
                    ...this.state,
                    message: data.message,
                    loading:false
                })
                setTimeout(function(){
                    this.setState({
                        ...this.state,
                        message:null,
                        email:null
                    })
                }.bind(this),3000)
            }
        })
        .catch(error => {
            if (error.response) {
                const data = error.response["data"];
                this.setState({
                    ...this.state,
                    error: data["errors"][0],
                    loading:false
                })
                setTimeout(function(){
                    this.setState({
                        ...this.state,
                        message:null,
                    })
                }.bind(this),3000)
                
              } 
              else if (error.request) {
                this.setState({
                    ...this.state,
                    error: "Something went wrong try again.",
                    loading:false
                })
                setTimeout(function(){
                    this.setState({
                        ...this.state,
                        message:null,
                    })
                }.bind(this),3000)
              } 
        })
    }
    
    render(){
        return(
            
            <div className="container main-wrapper">
                <div className="row">
                <div className="col-md-12">
                        <h2 className="heading text-center">Forgot <span className="user">Password</span></h2>
                    </div>
                <div className="col-md-12">
                {
                     this.state.message
                     ?
                        <div>
                            <div className="alert alert-primary">
                                {this.state.message}
                            </div>
                        </div>
                     :
                        this.state.error 
                        ?
                            <div>
                                <div className="alert alert-danger">
                                    {this.state.error}
                                </div>
                            </div>
                        :
                        <div></div>
                }
                </div>
                <div className="col-md-3">
                </div>
                    <div className="col-md-6">
                    <svg xmlns="http://www.w3.org/2000/svg" height="60px" viewBox="0 0 24 24" width="60px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"/></svg>
                        <form onSubmit={this.handleSubmit} className="form secondary-form">

                            <div className="form-field">
                                <input type="email" name='email' placeholder=" " autofill="off" required 
                                value={this.state.email || ""} onChange={this.handleChange}/>
                                <span className="label">Email</span>
                            </div>

                            {
                                this.state.loading
                                ?

                                
                                <div className="container">
                                    <div className="loader-button">
                                        <div className="spinner-border text-light" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="container">
                                    <button className="submit-btn" href="#">Change Password</button>
                                </div>
                            }

                        </form>
                    </div>
                </div>
            </div>
        );
    }
}


