import React,{Component} from 'react'
import {Link} from 'react-router-dom'
import copy from "copy-to-clipboard";
import {
    volunteerRegistration,
    deleteVolunteerID,
    archiveVolunteers,
} from '../../url'
import axios from 'axios'
import EditVolunteer from './EditVolunteer';

export default class UserCard extends Component{
    constructor(props){
        super(props);
        console.log(props)
        this.state = {
            editey: false,
            showAddress: false,
            copied:false,
            showClipBoardMessage:false,
            
            login:window.localStorage.getItem("loggedin"),
            uid:window.localStorage.getItem("uid"),
            client:window.localStorage.getItem("client"),
            "access-token":window.localStorage.getItem("token"),
            role:window.localStorage.getItem("role"),
        }
        
        this.showAdditionalDetails = this.showAdditionalDetails.bind(this)
        this.copyDetails = this.copyDetails.bind(this)
        this.handelHover = this.handelHover.bind(this)
        this.handelHoverOver = this.handelHoverOver.bind(this)
        this.archiveVolunteer = this.archiveVolunteer.bind(this)
        this.unarchiveVolunteer = this.unarchiveVolunteer.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.showEdit = this.showEdit.bind(this)
        console.log(this.state)
    }
    
    handleDelete(id, name, event){
        event.preventDefault();
        let person = prompt("Deleting will permanently erase the volunteer details of "+name+"\nType 'confirm' to delete permanently", "no");
        if(person != null){
            if(person.toLowerCase() === "confirm"){
                axios.delete(`${deleteVolunteerID}${id}`).then(response=>{
                    this.props.rerender();
                })
            }
        }
        else{
            console.log('cancelled')
        }
    }

    archiveVolunteer(id, name) {
        let description = prompt("Enter the reason for archiving the volunteer details of " + name);
        if (description != null) {
            axios({
                method: "put",
                url: archiveVolunteers + id + '/archive',
                data: {
                    "id": id,
                    "reason_for_archive": description,
                },
                headers: {
                    uid: this.state.uid,
                    client: this.state.client,
                    "Access-Token": this.state['access-token'],
                }
            })
            .then(response => {
                this.props.rerender()
            })
            .catch(error => {
                console.log(error);
            });
        }
    }    
    

    unarchiveVolunteer(id){
        axios({
            method: "put",
            url: archiveVolunteers+id+'/unarchive',
            data: {
                "id":id
            },
            headers: {
                uid:this.state.uid,
                client:this.state.client,
                "Access-Token":this.state['access-token'],
            }
          })
          .then(response => {
            this.props.rerender()
        })
        .catch(error => {
            console.log(error)
        })
    }


    showAdditionalDetails(event){
        event.preventDefault()

        this.setState(prevState => ({
            showAddress: !prevState.showAddress
        }))
    }

    showEdit(event){
        event.preventDefault()

        this.setState(prevState => ({
            editey: !prevState.editey
        }))
    }

    handelHover(event){
        event.preventDefault()
        this.setState({
            ...this.state,
            showClipBoardMessage:true
        })

    }

    handelHoverOver(event){
        event.preventDefault()
        this.setState({
            ...this.state,
            showClipBoardMessage:false,
        })

    }


    copyDetails(event){
        event.preventDefault()
        
        this.setState({
            ...this.state,
            showClipBoardMessage:false,
            copied:true
        })

        setTimeout(
            () => this.setState({ copied: false}), 
            2000
          );
        console.log(this.props)
        const details = 
        this.props.data.first_name+" "+ this.props.data.last_name+"\r\n"
        +"+91"+this.props.data.phone+"\r\n"
        // +"Taluk: "+this.props.taluk.name+"\r\n"
        +"Panchayath: "+this.props.panchayat.name+"\r\n"
        +"Police Staion: "+this.props.policeStation.name+"\r\n"
        // +"Resource: "
        // +this.props.data.assets.map(data =>(
        //     "\r\n"+data.asset_type
        // ))

        window.encodeURIComponent(details)

        copy(details);
        

    }


    render(){
        return(
            
            <div className="card-container">
                 {/* <p> {this.props.data.id}</p> */}
                 <h5>{this.props.data.first_name} {this.props.data.last_name}</h5>
                 <p>{this.props.data.phone}</p>

                <div>
                    <a href={"tel:+91"+ this.props.data.phone}>
                        <span className="userAction">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M6.54 5c.06.89.21 1.76.45 2.59l-1.2 1.2c-.41-1.2-.67-2.47-.76-3.79h1.51m9.86 12.02c.85.24 1.72.39 2.6.45v1.49c-1.32-.09-2.59-.35-3.8-.75l1.2-1.19M7.5 3H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.49c0-.55-.45-1-1-1-1.24 0-2.45-.2-3.57-.57-.1-.04-.21-.05-.31-.05-.26 0-.51.1-.71.29l-2.2 2.2c-2.83-1.45-5.15-3.76-6.59-6.59l2.2-2.2c.28-.28.36-.67.25-1.02C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1z"/></svg>
                        </span>
                    </a>

                    <a 
                    href={
                            "whatsapp://send?text=*Name:* "+this.props.data.first_name+" "+ this.props.data.last_name
                            +"%0a%0a*Phone:* +91"+this.props.data.phone
                            +"%0a%0a*Address:* %0a"+this.props.data.address
                            +"%0a"+
                                (
                                    this.props.village
                                    ?
                                        this.props.village.name
                                    :
                                        null
                                )
                            +"%0a"+
                                (
                                    this.props.taluk
                                    ?
                                        this.props.taluk.name
                                    :
                                        null
                                )
                            +"%0a"+this.props.data.pincode
                            +"%0a%0a*Resources:* "+
                            this.props.data.assets.map(data =>(
                                "%0a"+data.asset_type
                            ))
                            +"%0a%0a*Blood Group:* "+
                                (this.props.data.blood_group 
                                ?
                                    this.props.data.blood_group
                                :
                                    "Not Provided")
                            
                        } 
                    data-action="share/whatsapp/share">
                        

                        <span className="userAction" >
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92c0-1.61-1.31-2.92-2.92-2.92zM18 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM6 13c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm12 7.02c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"/></svg>
                        </span>
                    </a>
                    {
                        this.state.showClipBoardMessage && (this.state.copied === false)
                        ?
                            <span className="copy-to-clipboard">Copy To clipboard</span>
                        :
                            <span></span>

                    }
                    {
                        this.state.copied
                        ?
                            <span className="copy-to-clipboard">Copied</span>
                        :
                            <span></span>

                    }
                    
                    <span className="userAction" onClick={this.copyDetails} onMouseEnter={this.handelHover} onMouseLeave={this.handelHoverOver}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg>
                    </span>

                    {/* For editing volunteer details */}
                    <Link className="link" to={`/volunteers/edit/${this.props.data.id}`}>
                    <span className="userAction" >
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/> <path d="M 18.414062 2 C 18.158062 2 17.902031 2.0979687 17.707031 2.2929688 L 15.707031 4.2929688 L 14.292969 5.7070312 L 3 17 L 3 21 L 7 21 L 21.707031 6.2929688 C 22.098031 5.9019687 22.098031 5.2689063 21.707031 4.8789062 L 19.121094 2.2929688 C 18.926094 2.0979687 18.670063 2 18.414062 2 z M 18.414062 4.4140625 L 19.585938 5.5859375 L 18.292969 6.8789062 L 17.121094 5.7070312 L 18.414062 4.4140625 z M 15.707031 7.1210938 L 16.878906 8.2929688 L 6.171875 19 L 5 19 L 5 17.828125 L 15.707031 7.1210938 z"></path></svg>
                    </span>
                    </Link>

                    {/* For archiving volunteer detail */}
                    <button className="archive-btn" onClick={() => {this.props.data.archived?this.unarchiveVolunteer(this.props.data.id):this.archiveVolunteer(this.props.data.id, this.props.data.first_name)}}>{this.props.data.archived?"Unarchive":"Archive"}</button>
                    

                    <span className="userAction" onClick={this.showAdditionalDetails}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/></svg>
                    </span>

                    
                </div>
                {this.state.editey?(<EditVolunteer id={this.props.data.id}/>):<div/>}
                {
                    this.state.showAddress
                    ?
                        <div className="additional-details text-left">

                            <h6>Address</h6>
                            {this.props.data.address ? <p className="address">{this.props.data.address},</p>:<p></p>}
                            <span className="address">{this.props.taluk.name}, </span>
                            <span  className="address">{this.props.village.name} </span>
                            <p>{this.props.data.pincode}</p>
                            
                            <div className='additional_details_flex'>
                                <div className='additional_details_flex_column'>
                                    <h6>Blood Group</h6>
                                    <p>
                                        {this.props.data.blood_group 
                                        ?
                                        this.props.data.blood_group
                                        :
                                        "Not Provided"}
                                    </p>
                                </div>

                                {this.props.data.trauma_id ?
                                <div className='additional_details_flex_column'>
                                    <h6>Trauma ID</h6>
                                    <p>
                                        {this.props.data.trauma_id}
                                    </p>
                                </div> : <div></div>
                                }
                                {this.props.data.archived?
                                <div>
                                    <h6>Archival Description</h6>
                                    {this.props.data.reason_for_archive?<p>{this.props.data.reason_for_archive}</p>:<p>Not Provided</p>}
                                </div>:
                                <div></div>}
                            </div>
                            {this.state.role==="superadmin" 
                            ?
                            <button className='delete-btn' onClick={(event)=>{this.handleDelete(this.props.data.id, this.props.data.first_name, event)}}>
                                Delete <svg  className="userAction" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M 10.806641 2 C 10.289641 2 9.7956875 2.2043125 9.4296875 2.5703125 L 9 3 L 4 3 A 1.0001 1.0001 0 1 0 4 5 L 20 5 A 1.0001 1.0001 0 1 0 20 3 L 15 3 L 14.570312 2.5703125 C 14.205312 2.2043125 13.710359 2 13.193359 2 L 10.806641 2 z M 4.3652344 7 L 5.8925781 20.263672 C 6.0245781 21.253672 6.877 22 7.875 22 L 16.123047 22 C 17.121047 22 17.974422 21.254859 18.107422 20.255859 L 19.634766 7 L 4.3652344 7 z"></path></svg>
                            </button>
                            :
                            <div></div>
                            }

                        </div>
                    :
                        <div>
                        </div>
                }

                <div className="skills">
                  <ul>
                  <h6>Resource</h6>
                    {
                        this.props.data.assets.map(data =>(
                            <li key={data.id}>{data.asset_type}</li>
                        ))
                    }
                  </ul>
                  
                
                </div>
                
              </div>
        )
    }
} 