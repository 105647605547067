import axios from 'axios';
import React,{Component} from 'react'
import {
    verifyVolunteers,
    verifyInstitutions,
    archiveInstitutions,
    archiveVolunteers
} from '../../url'

export default class Verify extends Component{

    constructor(props) {
        super(props);
        this.state={

            uid:window.localStorage.getItem("uid"),
            client:window.localStorage.getItem("client"),
            token:window.localStorage.getItem("token"),
        }

        this.verifyVolunteer = this.verifyVolunteer.bind(this)
        this.archiveVolunteer = this.archiveVolunteer.bind(this)
        this.verifyInstitution = this.verifyInstitution.bind(this)
        this.archiveInstitution = this.archiveInstitution.bind(this)
    }

    verifyVolunteer(id){
        axios({
            method: "post",
            url: verifyVolunteers+id+'/verify',
            data: {
                "id":id
            },
            headers: {
                uid:this.state.uid,
                client:this.state.client,
                "Access-Token":this.state.token,
            }
          })
        .then(response => {

            this.props.rerender();
        })
        .catch(error => {
             
        })
    }

    archiveVolunteer(id){
        axios({
            method: "put",
            url: archiveVolunteers+id+'/archive',
            data: {
                "id":id
            },
            headers: {
                uid:this.state.uid,
                client:this.state.client,
                "Access-Token":this.state.token,
            }
          })
          .then(response => {
            
            this.props.rerender()
        })
        .catch(error => {
             
        })
    }
    
    verifyInstitution(id){
        axios({
            method: "post",
            url: verifyInstitutions+id+'/verify',
            data: {
                "id":id
            },
            headers: {
                uid:this.state.uid,
                client:this.state.client,
                "Access-Token":this.state.token,
            }
          })
        .then(response => {
            this.props.rerender()
        })
        .catch(error => {
             
        })
    }
    
    archiveInstitution(id){
        axios({
            method: "post",
            url: archiveInstitutions+id+'/archive',
            data: {
                "id":id
            },
            headers: {
                uid:this.state.uid,
                client:this.state.client,
                "Access-Token":this.state.token,
            }
          })
          .then(response => {
            this.props.rerender()
        })
        .catch(error => {
             
        })
    }
    
    render(){
        const data = this.props.data
        return(
            <div className="col-lg-4">
                {this.props.type === "User"
                 ?
                    <div>
                        <div className="user-box">
                            <h5 className="userbox-name">{data.first_name} {data.last_name}</h5>
                            <p className="userbox-name">{data.phone}</p>
                            <p className="userbox-name">{data.email}</p>
                            

                            <p><strong>Resources</strong></p>
                            <ol>
                            {
                                data.assets.map(asset => (
                                    <li key={asset.id}>{asset.asset_type}</li>
                                ))
                            }
                            </ol>
                            
                            <p><strong>Address</strong></p>
                            <p>{data.address}</p>
                            {
                                this.props.village
                                ?
                                    <p>{this.props.village.name}</p>
                                :
                                    null
                            }
                            <p>{data.pincode}</p>

                            <div className="decision-container">
                                <span onClick={() => this.verifyVolunteer(data.id)} className="verify-btn">Verify</span>
                                <span onClick={() => this.archiveVolunteer(data.id)} className="remove-btn">Remove</span>
                            </div>
                        </div>
                    </div>
                 :
                    
                        <div>
                            <div className="user-box">
                                <h5 className="userbox-name">{data.name}</h5>
                                <p className="userbox-name">{data.phone1}, {data.phone2}</p>
                                <div className="institution-type">
                                    <p>{data.email}</p>
                                    <p>{data.institution_type}</p>
                                    <p>{data.authority_type}</p>
                                </div>
                                <br/>
                                <p><strong>Address</strong></p>
                                <p>{data.address}</p>
                                {
                                    this.props.village
                                    ?
                                        <p>{this.props.village.name}</p>
                                    :
                                        null
                                }
                                <p>{data.pincode}</p>

                                <div className="decision-container">
                                    <span onClick={() => this.verifyInstitution(data.id)} className="verify-btn">Verify</span>
                                    <span onClick={() => this.archiveInstitution(data.id)} className="remove-btn">Remove</span>
                                </div>
                            </div>
                        </div>      
                }

            </div>
        );
    }
}