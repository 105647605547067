import React,{Component,useState,useRef,useMemo,useCallback} from 'react';
import {MapContainer, TileLayer, Marker, Popup, ZoomControl,useMapEvents } from 'react-leaflet'

function LocationMarker({parentCallback}) {
    const [position, setPosition] = useState({lat: 20.0063,lng: 77.006})
    const [draggable, setDraggable] = useState(false)

    const map = useMapEvents({
      click() {
        map.locate()
      },
      locationfound(e) {
        setPosition(e.latlng)
        parentCallback(e.latlng)
        map.flyTo(e.latlng, map.getZoom())
      },
    })   

    const markerRef = useRef(null)
    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current
          if (marker != null) {
            setPosition(marker.getLatLng())
            parentCallback(marker.getLatLng())
          }
        },
      }),
      [parentCallback],
    )

    const toggleDraggable = useCallback(() => {
      setDraggable((d) => !d)
    }, [])

    return position === null ? null : (
      <Marker draggable={draggable}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}>
        <Popup minWidth={90}>
        <span onClick={toggleDraggable}>
          {draggable
            ? 'Marker is draggable'
            : 'Click here to make marker draggable'}
        </span>
      </Popup>
      </Marker>
    )
  }

export default class Maps extends Component{
    constructor(props){
        super(props);

        this.state = {
            lat:null,
            long:null,
        }
        this.callback = this.callback.bind(this)
    }

    callback = (position) => {
       this.setState({
           lat:position.lat,
           long:position.lng,
       })
    }

    render(){
      console.log(this.state);
        return(
            <MapContainer center={[11.0510,76.0711]} zoom={11} zoomControl={false} scrollWheelZoom={false} className="map">
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <ZoomControl position="bottomright"/>
                <LocationMarker parentCallback={this.callback}/>
            </MapContainer>
        );
    }

}