import React,{Component} from 'react';

import {
   volunteerRegistration,
   getTaluks,
   volunteerResource,
   getVillage,
   getPanchayat,
   getPoliceStation,
   getVolunteerDetails
} from '../../url'
import axios from 'axios';
import { withRouter } from "react-router";
var id;

class EditVolunteer extends Component{
  
   constructor(props){
       super(props);
       console.log("props", props)
       console.log("props", props?.match?.params?.id)
        
       this.state = {
           first_name: '',
           last_name: '',
           phone:'',
           address:'',
           region:'',
           panchayat:'',
           policeStation:'',
           blood_group:'',
           pincode:'',
           volunteer_asset_attributes:[],
           email:'',
           birthday:'',
           gender:'',
           trauma_id:'',

           error:null,
           loading:false,
           info:false,
           noResourceFound:null,

           taluk:'',
           showBlood:null,

           resource_name:'',
           talukList:[],
           villageList:[],
           panchayatList:[],
           policeStationList:[],
           villageListFiltred:[],
           resourceList:[],
           resource_attribute:[],

            login:window.localStorage.getItem("loggedin"),
            uid:window.localStorage.getItem("uid"),
            client:window.localStorage.getItem("client"),
            "access-token":window.localStorage.getItem("token"),

           
       }

       id = props.match.params.id;
       axios.get(`${getVolunteerDetails}${id}`,{
           headers:{
               'Content-Type':'application/json',
           }
       })
        .then(response => {
           var volunteer = response.data.volunteer
           console.log(volunteer)
           volunteer.assets.map((x) => {
            var resourceName = x;
            this.setState(prevState => ({
                    resource_attribute: 
                        prevState.resource_attribute.includes(resourceName["asset_type"]) 
                        ?
                            [...prevState.resource_attribute]
                    :
                        [...prevState.resource_attribute,resourceName["asset_type"]],
                
                volunteer_asset_attributes:
                    prevState.resource_attribute.includes(resourceName["asset_type"]) 
                    ?
                        [...prevState.volunteer_asset_attributes]
                    :
                    [ 
                        ...prevState.volunteer_asset_attributes,{
                        "asset_id": resourceName["id"]
                    }],         
    
            }))
        });
        console.log(this.state.resource_attribute)
           this.setState({
               first_name: volunteer.first_name,
               last_name: volunteer.last_name,
               phone: volunteer.phone,
               address: volunteer.address,
               region: volunteer.village_id,
               panchayat: volunteer.panchayath_id,
               taluk: volunteer.taluk,
               policeStation: volunteer.police_station_id,
               blood_group: volunteer.blood_group,
               pincode: volunteer.pincode,
               volunteer_assets_attributes: volunteer.volunteer_asset_attributes, //try changing assets to asset here on left
               email: volunteer.email,
               birthday: volunteer.birthday,
               gender: volunteer.gender,
               trauma_id: volunteer.trauma_id,
           })
           console.log(this.state.volunteer_asset_attributes)
       })
          

       this.handleChange = this.handleChange.bind(this);
       this.handleBirthdayChange = this.handleBirthdayChange.bind(this);
       this.onChangeGender = this.onChangeGender.bind(this);
       this.handleSubmit = this.handleSubmit.bind(this);
       this.handleAddService = this.handleAddService.bind(this);
       this.handleTalukChange = this.handleTalukChange.bind(this);
       
   }


   componentDidMount(){
       axios.get(getTaluks)
       .then(response => {
           var taluk = response.data

           this.setState({
               talukList:[...taluk]
           })
       })
       .catch(error => {
            
       })

       axios.get(getVillage)
       .then(response => {
           var villages = response.data

           this.setState({
               villageList:[...villages]
           })
       })
       .catch(error => {
            
       })

       axios.get(getPanchayat)
       .then(response => {
           var panchayat = response.data
           

           this.setState({
               panchayatList:[...panchayat]
           })
       })
       .catch(error => {
            
       })

       axios.get(getPoliceStation)
       .then(response => {
           var policeStation = response.data
           this.setState({
               policeStationList:[...policeStation]
           })
       })
       .catch(error => {
            
       })

       axios.get(
        volunteerResource,
        {
          headers: {
            uid: this.state.uid,
            client: this.state.client,
            "Access-Token": this.state["access-token"],
            'Content-Type': 'application/json',
          },
        }
        )
       .then(response => {

           var resource = response.data
           this.setState({
               ...this.state,
               resourceList:[...resource]
           })
       })
       .catch(error => {
            
       })

    
   }

   handleSubmit(e) {
       e.preventDefault();
       console.log(this.state)

       this.setState({
           ...this.state,
           loading:true,
           error:null,
           info:null,
           noResourceFound:null,
       })

       //commented as there is issue with resource right now
       if(this.state.resource_attribute.length === 0)
       {
           this.setState({
               ...this.state,
               loading:false,
               noResourceFound:true,
               info:"Add Resource To Submit"
           })
       }
       else
       {
           axios.put(`${volunteerRegistration}/${id}`,
            {
               "volunteer": {
                   "first_name": this.state.first_name,
                   "last_name": this.state.last_name,
                   "phone": this.state.phone,
                   "address": this.state.address,
                   "village_id": this.state.region,
                   "panchayath_id": this.state.panchayat,
                   "police_station_id":this.state.policeStation,
                   "pincode": this.state.pincode,
                   "blood_group": this.state.blood_group,
                   "volunteer_assets_attributes": this.state.volunteer_asset_attributes,
                   "email":this.state.email,
                   "birthday":this.state.birthday,
                   "gender":this.state.gender,
                   "trauma_id":this.state.trauma_id,
               }                    
           },
           {
            headers: {
              uid: this.state.uid,
              client: this.state.client,
              "Access-Token": this.state["access-token"],
              'Content-Type': 'application/json',
            }})
           .then(response => {
               this.setState({
                   ...this.state,
                   loading:false,
                   info:'Thank you for Updating',

                   first_name:'',
                   last_name:'',
                   phone:'',
                   address:'',
                   taluk:'',
                   region:'',
                   panchayat:'',
                   blood_group:'',
                   pincode:'',
                   trauma_id:'',
                   volunteer_asset_attributes:[],
                   email:'',
                   birthday:'',
                   gender:'',
                   resource_name:'',
                   resource_attribute:[]
               })

               setTimeout(function(){
                   this.setState({
                       info:null,
                       error:null,
                       loading:false,
                   })
               }.bind(this),3000)
   
               window.scrollTo({
                   top: 0,
                   behavior: "smooth"
                 });
           })
           .catch((error) => {
               this.setState({
                   ...this.state,
                   loading:false,
                   error: "Something went wrong"
               })   
               console.log(error)
   
               window.scrollTo({
                   top: 0,
                   behavior: "smooth"
                 });
           })

       }   
   }

   handleChange(event) {
       console.log(event.target.name,event.target.value);
       this.setState({
           ...this.state,
           [event.target.name]: event.target.value,
       });
   }

   handleBirthdayChange(event) {
       this.setState({
           ...this.state,
           [event.target.name]:event.target.value,
       });
   }

   onChangeGender(event) {
       this.setState({
           ...this.state,
           gender:event.target.value
       })
   }


   getTalukValue(taluk){
       console.log(taluk)
       this.setState(prevState => ({
           villageListFiltredDetails : prevState.villageList.filter(village => village["taluk_id"] === taluk),
       }))
   }

   

   handleTalukChange(event){


       this.setState(prevState => ({
           [event.target.name]: event.target.value,
           villageListFiltred : prevState.villageList.filter(village => village["taluk_id"] === event.target.value),
           region:''
       }))
   }

   handleAddService(e){
       e.preventDefault()
       this.setState({
           ...this.state,
           loading:false,
           noResourceFound:false,
           info:null
       })

       var resourceName = this.state.resourceList.find(resource => 
           resource.id === this.state.resource_name)

       if(resourceName === undefined)
       {
           this.setState({
               ...this.state,
               loading:false,
               noResourceFound:true,
               info:"Select Resource to Add"
           })
       }

       else
       {
           this.setState(prevState => ({
               resource_attribute: 
                   prevState.resource_attribute.includes(resourceName["asset_type"]) 
                   ?
                       [...prevState.resource_attribute]
                   :
                       [...prevState.resource_attribute,resourceName["asset_type"]],
               
               volunteer_asset_attributes:
                   prevState.resource_attribute.includes(resourceName["asset_type"]) 
                   ?
                       [...prevState.volunteer_asset_attributes]
                   :
                   [ 
                       ...prevState.volunteer_asset_attributes,{
                       "asset_id": resourceName["id"]
                   }],         
   
           }))
       }
       console.log(this.state.resource_attribute)
       console.log(this.state.volunteer_asset_attributes)
   }

   handleRemoveService(index){

       let resourceArray = [...this.state.resource_attribute]
       let volunteer_asset_attributes = [...this.state.volunteer_asset_attributes]

       if (index !== -1)
       {
           resourceArray.splice(index, 1);
           volunteer_asset_attributes.splice(index, 1);
       }

       this.setState({
           resource_attribute:[...resourceArray],
           volunteer_asset_attributes:[...volunteer_asset_attributes]
       }) 
       console.log(this.state.resource_attribute)
       console.log(this.state.volunteer_asset_attributes)
   }

   render(){
       return(
           
           <div className="container main-wrapper">
               <div className="row ">
               <div className="col-md-12">
                   <h2 className="heading text-center">Volunteer<span className="user"> Details Edit</span></h2>
               </div>
               <div className="col-md-3">
               </div>
                   <div className="col-md-6">
                   {
                       this.state.error
                       ?
                           <div className="alert alert-danger">
                                {this.state.error}   
                           </div>   
                       :
                           
                           <div></div>
                   }
                   
                   {
                       this.state.info
                       ?
                           <div className="alert alert-primary">
                               {this.state.info}
                           </div>
                       :
                           
                            <div></div>
                   }
                   <svg xmlns="http://www.w3.org/2000/svg" height="50px" viewBox="0 0 24 24" width="60px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"/></svg>
                       <form onSubmit={this.handleSubmit} className="form secondary-form">

                           <div className="form-field">
                               <input type="text" name='first_name' placeholder=" " autofill="off" required 
                               value={this.state.first_name} onChange={this.handleChange}/>
                               <span className="label">First Name <span className="formrequired">*</span></span>
                           </div>

                           <div className="form-field">
                               <input type="text" name='last_name' placeholder=" " autofill="off" required 
                               value={this.state.last_name} onChange={this.handleChange}/>
                               <span className="label">Last Name<span className="formrequired">*</span></span>
                           </div>

                           <div className="form-field">
                               <input type="tel" name='phone' placeholder=" " autofill="off" required 
                               value={this.state.phone} onChange={this.handleChange} pattern={"[1-9]{1}[0-9]{9}"}/>
                               <span className="label">Phone No.<span className="formrequired">*</span></span>
                           </div>

                           <div className="form-field">
                               <input type="email" name='email' placeholder=" " autofill="off"  
                               value={this.state.email} onChange={this.handleChange}/>
                               <span className="label">Email</span>
                           </div>

                           
                           <div className="form-field">
                               <input type="date" name='birthday'  placeholder=" " autofill="off"  
                               value={this.state.birthday} onChange={this.handleBirthdayChange}/>
                               <span className="label">Birthday</span>
                           </div>
                           


                           <div className="form-field">
                                       <select type="" name='gender' placeholder=" " autofill="off"  required
                                       value={this.state.gender} onChange={this.onChangeGender}>
                                           <option value={""} disabled={true}>Select Gender</option>                            
                                           <option value={"male"}>Male</option>                            
                                           <option value={"female"}>Female</option>                            
                                           <option value={"other"}>Other</option>                          
                                       </select>
                                       <span className="select-label gender-title" >Gender<span className="formrequired">*</span></span>
                                       
                           </div>


                           <div className="form-field">
                               <textarea type="textarea" name='address' placeholder=" " autofill="off"  
                               value={this.state.address} onChange={this.handleChange} rows={3}/>
                               <span className="label">Address </span>
                           </div>        

                           {/* *********************************************************************************
                                                           Taluk
                           ***********************************************************************************/}

                           <div className="form-field">
                               <select type="" name='taluk' placeholder=" " autofill="off"  
                               value={this.state.taluk} onChange={this.handleTalukChange}>
                                   <option value={""} disabled={true}>Select Taluk</option>

                                   {
                                       this.state.talukList.map(region => (
                                           <option  key={region.id} value={region.id}>{region.name}</option>
                                       ))
                                   }
                             
                           
                               </select>
                               
                               <span className="select-label" >Taluk </span>
                           </div>


                           {/* *********************************************************************************
                                                           Village
                           ***********************************************************************************/}
                           <div className="form-field">
                               <select type="" name='region' placeholder=" " autofill="off" required 
                               value={this.state.region} onChange={this.handleChange}>
                                   <option value={''} disabled={true}>Select Village</option>

                                   {
                                       this.state.villageList.map(region => (
                                           <option  key={region.id} value={region.id}>{region.name}</option>
                                       ))
                                   }
                             
                           
                               </select>
                               
                               <span className="select-label" >Village<span className="formrequired">*</span></span>
                           </div>
                           {/* *********************************************************************************
                                                           Panchayat
                           ***********************************************************************************/}
                           <div className="form-field">
                               <select type="" name='panchayat' placeholder=" " autofill="off" required 
                               value={this.state.panchayat} onChange={this.handleChange}>
                                   <option value={""} disabled={true} >Panchayat</option>

                                   {
                                       
                                       this.state.panchayatList.map(region => (
                                           <option  key={region.id} value={region.id}>{region.name}</option>
                                       ))
                                   }
                             
                           
                               </select>
                               
                               <span className="select-label" >Panchayat<span className="formrequired">*</span></span>
                           </div>
                           {/* *********************************************************************************
                                                           Police Station
                           ***********************************************************************************/}
                           <div className="form-field">
                               <select type="" name='policeStation' placeholder=" " autofill="off" required 
                               value={this.state.policeStation} onChange={this.handleChange}>
                                   <option value={''} disabled={true}>Select Police Station</option>

                                   {
                                       this.state.policeStationList.map(station => (
                                           <option  key={station.id} value={station.id}>{station.name}</option>
                                       ))
                                   }
                             
                           
                               </select>
                               
                               <span className="select-label" >Police Station<span className="formrequired">*</span></span>
                           </div>
                           {/* *********************************************************************************
                                                           Pincode
                           ***********************************************************************************/}
                           <div className="form-field">
                               <input type="tel" name='pincode' placeholder=" " autofill="off"  
                               value={this.state.pincode} onChange={this.handleChange} pattern={"[1-9]{1}[0-9]{5}"}/>
                               <span className="label">Pincode</span>
                           </div>

                           <div className="form-field">
                               <textarea type="textarea" name='trauma_id' placeholder=" " autofill="off"  
                               value={this.state.trauma_id} onChange={this.handleChange} rows={2}/>
                               <span className="label">Trauma ID (if applicable)</span>
                           </div>    
                           {/* *********************************************************************************
                                                           Blood Group
                           ***********************************************************************************/}
                           <div className="form-field">
                                       <select type="" name='blood_group' placeholder=" " autofill="off"  
                                       value={this.state.blood_group} onChange={this.handleChange}>
                                           <option value={""} disabled={true}>Select Blood Group</option>                            
                                           <option value={"a_positive"}>A +ve</option>                            
                                           <option value={"a_negative"}>A -ve</option>                            
                                           <option value={"b_positive"}>B +ve</option>                            
                                           <option value={"b_negative"}>B -ve</option>                            
                                           <option value={"o_positive"}>O +ve</option>                            
                                           <option value={"b_negative"}>O -ve</option>                            
                                           <option value={"ab_positive"}>AB +ve</option>                            
                                           <option value={"ab_negative"}>AB -ve</option>                            
                                   
                                       </select>
                                       <span className="select-label" >Blood Group</span>
                                       <div className="add-container"> 
                                       </div>
                           </div>


                           {/* *********************************************************************************
                                                           Resource
                           ***********************************************************************************/}
                           <div className="form-field">
                               <select type="" name='resource_name' placeholder=" " autofill="off"  
                               value={this.state.resource_name} onChange={this.handleChange}>
                                   <option value={""} disabled={true}>Select Resource</option>

                                   {
                                       this.state.resourceList.map(resource => (
                                           <option key={resource.id} value={resource.id}>{resource.asset_type}</option>
                                       ))
                                   }
                             
                           
                               </select>
                               
                               <span className="select-label" >Resource Available<span className="formrequired">*</span></span>
                               <div className="add-container">
                                  
                               <button className="add-btn" onClick={this.handleAddService}>Add Services</button>
                               </div>
                               Multiple Resources can be added
                           </div>
                           
                           
                                   
                           {
                               this.state.noResourceFound
                               ?
                               <div className="alert alert-primary">
                                   {this.state.info}
                               </div>
                               :
                                   <div>
                                   </div>
                           }
                           
                           <div className="jumbotron">
                               {
                                   this.state.resource_attribute.length === 0
                                   ?
                                   <p>no resource added</p>
                                       
                                   :
                                   <div>
                                    <div>
                                       {
                                           this.state.resource_attribute.map((resource,index) =>
                                               <span key={index} className="btn btn-outline-primary resource">
                                                   {resource}
                                                   <svg  onClick={()=>{this.handleRemoveService(index)}}   xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="24px" className="closeBtn" fill="#007bff"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/></svg>
                                               </span>
                                           )
                                        }
                                    </div>
                                    {/* <div>
                                        {

                                            this.state.assets.map((asset_type,id) =>
                                            <span key={id} className="btn btn-outline-primary resource">
                                                   {asset_type}
                                                   <svg  onClick={()=>{this.handleRemoveService(id)}}   xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="24px" className="closeBtn" fill="#007bff"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/></svg>
                                               </span>
                                           )  
                                        } 
                                   </div> */}
                                   </div>
                               }
                           </div>

                           {
                               this.state.loading
                               ?
                               <div className="container">
                                   <div className="loader-button">
                                       <div className="spinner-border text-light" role="status">
                                           <span className="sr-only">Loading...</span>
                                       </div>
                                   </div>
                               </div>
                               :
                               <div className="container">
                                   <button className="submit-btn" href="#">Update Details</button>
                               </div>
                           }

                       </form>
                   </div>
               </div>
           </div>
       );
   }
}

export default withRouter(EditVolunteer)