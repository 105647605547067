import React, { Component } from 'react';
import { getPoliceStation, inviteAdmin } from '../../url';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

export default class InviteNormalUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      login: window.localStorage.getItem("loggedin"),
      editey: false,
      policeStationList:[],
      policeStation:'',
      loading: false,
      error: null,
      info: null,
      modal: false,
      admin_new: false,
      new_admin_email:'',
      new_admin_name:'',
      name:'',
      id:'',
      admins:[],
      uid: window.localStorage.getItem("uid"),
      client: window.localStorage.getItem("client"),
      "access-token": window.localStorage.getItem("token"),
    };

    
    this.getPoliceStation = this.getPoliceStation.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handleDeleteAdmin = this.handleDeleteAdmin.bind(this);
    this.handleAdminCreate = this.handleAdminCreate.bind(this);
  }
   
  componentDidMount() {
    this.setState({
      ...this.state,
      loading: true,
    });
    
    this.getPoliceStation();
  }
  
  getPoliceStation() {
    axios.get(
      getPoliceStation,
      {
        headers: {
          uid: this.state.uid,
          client: this.state.client,
          "Access-Token": this.state["access-token"],
          'Content-Type': 'application/json',
        },
      }
      )
      .then(response => {
        const policeStation = response.data;
        console.log(policeStation)
  
        this.setState({
          ...this.state,
          policeStationList: [...policeStation],
        });
      })
      .catch(error => {
        console.error(error);
      });
  }  

  handleEdit(id, name) {
    let value = prompt("Enter Updated Name of Police Station: " + name, name);
    axios.put(`${getPoliceStation}/${id}`,
    {
      "name": value,                   
    },
    {
      headers: {
        uid: this.state.uid,
        client: this.state.client,
        "Access-Token": this.state["access-token"],
        'Content-Type': 'application/json',
      }})
      .then(response => {
              console.log(response);  
              this.getPoliceStation();             
           })
           .catch((error) => {
               console.log(error)
           })
  }

  handleDelete(id, name) {
    let person = prompt("Deleting will permanently erase the Police Station "+name+"\nType 'confirm' to delete permanently", "no");
    if(person != null){
        if(person.toLowerCase() === "confirm"){
          axios.delete(
            `${getPoliceStation}/${id}`,
            {
              headers: {
                uid: this.state.uid,
                client: this.state.client,
                "access-token": this.state["access-token"],
                'Content-Type': 'application/json',
              },
            }
          )
            .then(response => {
              console.log("deleted");
              this.getPoliceStation();
            })
            .catch((error) => {
              console.log(error);
            });    
        }
    } 
    else{
        console.log('cancelled')
    }
  }
  
  handleDeleteAdmin(adminID, name, policeStationID, index) {
    let person = prompt("Deleting will permanently delete "+name+" as Admin.\nTo add as admin again, email invitation is required. \nType 'confirm' to delete permanently", "no");
    if(person != null){
      if(person.toLowerCase() === "confirm"){
        axios.put(
            `${getPoliceStation}/${policeStationID}/remove_admin`,
            {
              admin_id: adminID,
            },
            {
              headers: {
                uid: this.state.uid,
                client: this.state.client,
                "access-token": this.state["access-token"],
                'Content-Type': 'application/json',
              },
            }
          )
            .then(response => {
              console.log("deleted");
              this.getPoliceStation();
              let adminsArray = [...this.state.admins]
              if (index !== -1)
              {
                  adminsArray.splice(index, 1);
              }
              this.setState({
                  admins:[...adminsArray],
              })
              console.log(this.state)
            })
            .catch((error) => {
              console.log(error);
            });             
        }
    } 
    else{
        console.log('cancelled')
    }
  }


  handleCreate() {
    let name = prompt("Enter the name of the new Police Station to be added");
    console.log(name)
    if(name != null){
      axios.post(
        getPoliceStation,
        {
          "name": name,
        },
        {
          headers: {
            uid: this.state.uid,
            client: this.state.client,
            "Access-Token": this.state["access-token"],
            'Content-Type': 'application/json',
          },
        }
      )
        .then(response => {
          console.log(response);
          this.getPoliceStation();
        })
        .catch((error) => {
          console.log(error)
        });      
    }
    else{
        console.log('cancelled')
    }
  }


  handleAdminCreate(policeStationID) {
    if(this.state.new_admin_name===''){
      toast.error('Enter Valid Admin Name')
    }
    if(this.state.new_admin_email===''){
      toast.error('Enter Valid Admin Email')
    }
    else{
      this.setState({
        ...this.state,
        loading:true
      })
      axios({
        method: "post",
        url: inviteAdmin,
        data: {
          email: this.state.new_admin_email,
          police_station_id: this.state.id,
          name: this.state.new_admin_name,
        },
        headers: {
          uid:this.state.uid,
              client:this.state.client,
              "access-token":this.state["access-token"],
            }
      })
      .then(response => {
        this.setState({
          ...this.state,
          info:response.data.success,
          new_admin_email:"",
          admin_new: false,
        })
        toast.success(response.data.success)
      })
      .catch(error => {
        toast.error(error)
          if (error.response) {
                this.setState({
                  ...this.state,
                  loading:false,
                  error: "Something went wrong. User might already Exist"
              })
            } 
            else if (error.request) {
                this.setState({
                    ...this.state,
                    loading:false,
                    error: "Something went wrong"
                })
            } 
        })
    }
}

handleModal(PoliceStatioID, PoliceStationName, PoliceStationAdmins){
    this.setState({
      ...this.state,
      id: PoliceStatioID,
      name: PoliceStationName,
      admins: PoliceStationAdmins,
      modal: true,
    });
  }

  
  render() {
    return (
      <div className="container main-wrapper">
        {
          this.state.login
            ?
            <div className="row resource_admin">
              <Toaster />

              {/* Modal */}
              {this.state.modal?
              <div className="admin_control_modal">
                <div className='admin-modal-content'>
                  <span onClick={()=>this.setState({...this.state,modal: false,})} className="close">&times;</span>
                  <h5>Police Station - {this.state.name}</h5>
                  <h6>Details of Admins</h6>
                  {this.state.admins.map((admin, index) => (
                    <div key={admin.id} className='admin-modal-admins'>
                      <p>{admin.name}</p>
                      <p>{admin.email}</p>
                      <span onClick={() => this.handleDeleteAdmin(admin.id, admin.name, this.state.id, index)}>
                            <svg className="userAction admin-modal-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#ffff"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M 10.806641 2 C 10.289641 2 9.7956875 2.2043125 9.4296875 2.5703125 L 9 3 L 4 3 A 1.0001 1.0001 0 1 0 4 5 L 20 5 A 1.0001 1.0001 0 1 0 20 3 L 15 3 L 14.570312 2.5703125 C 14.205312 2.2043125 13.710359 2 13.193359 2 L 10.806641 2 z M 4.3652344 7 L 5.8925781 20.263672 C 6.0245781 21.253672 6.877 22 7.875 22 L 16.123047 22 C 17.121047 22 17.974422 21.254859 18.107422 20.255859 L 19.634766 7 L 4.3652344 7 z"></path></svg>
                      </span>
                    </div>
                  ))}
                  <button onClick={()=>this.setState({...this.state, admin_new:!this.state.admin_new})} className='modal-add-admin-button'>Add Admin</button>
                  {this.state.admin_new &&
                  <div className="modal-add-admin">
                    <input className='admin-modal-input' onChange={(event)=>{this.setState({...this.state, new_admin_name: event.target.value })}} placeholder='Enter Admin Name'></input>
                    <input className='admin-modal-input' onChange={(event)=>{this.setState({...this.state, new_admin_email: event.target.value })}} placeholder='Enter Admin Email'></input>
                    <button className='modal-submit-admin-button' onClick={()=>this.handleAdminCreate(this.state.id)}>Submit Admin</button>
                  </div>
                  }
                </div>
              </div> 
              :
              <div></div>
              }
              {/* Modal Ends */}

              <div className="col-md-12">
                <h2 className="heading text-center">Police Station <span className="user">Admin Control</span></h2>
              </div>
              <div className='police-station-admin'>
              <table>
                <tr> 
                    <th className="text-center">Police Station</th> 
                    <th className="text-center">Admins</th>
                    <th className="text-center">Actions</th> 
                </tr>
                <tbody>
                {this.state.policeStationList.map(policeStation => (
                  <tr  key={policeStation.id}> 
                      <td>{policeStation.name}</td> 
                      <td>
                        {policeStation.admins.map((admin)=>(
                        <p>{admin.name}</p>
                        ))}
                      </td> 
                      <td>
                        <div className="policestation_admin_crud">
                          <span onClick={() => this.handleModal(policeStation.id, policeStation.name, policeStation.admins)}>
                            <svg fill="#000000" width="24px" height="24px" viewBox="0 -64 640 640" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"/><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"/><g id="SVGRepo_iconCarrier"><path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h274.9c-2.4-6.8-3.4-14-2.6-21.3l6.8-60.9 1.2-11.1 7.9-7.9 77.3-77.3c-24.5-27.7-60-45.5-99.9-45.5zm45.3 145.3l-6.8 61c-1.1 10.2 7.5 18.8 17.6 17.6l60.9-6.8 137.9-137.9-71.7-71.7-137.9 137.8zM633 268.9L595.1 231c-9.3-9.3-24.5-9.3-33.8 0l-37.8 37.8-4.1 4.1 71.8 71.7 41.8-41.8c9.3-9.4 9.3-24.5 0-33.9z"/></g></svg>
                          </span>
                          <span onClick={() => this.handleEdit(policeStation.id, policeStation.name)}>
                            <svg className="userAction"  xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /> <path d="M18.414062 2 C 18.158062 2 17.902031 2.0979687 17.707031 2.2929688 L 15.707031 4.2929688 L 14.292969 5.7070312 L 3 17 L 3 21 L 7 21 L 21.707031 6.2929688 C 22.098031 5.9019687 22.098031 5.2689063 21.707031 4.8789062 L 19.121094 2.2929688 C 18.926094 2.0979687 18.670063 2 18.414062 2 z M 18.414062 4.4140625 L 19.585938 5.5859375 L 18.292969 6.8789062 L 17.121094 5.7070312 L 18.414062 4.4140625 z M 15.707031 7.1210938 L 16.878906 8.2929688 L 6.171875 19 L 5 19 L 5 17.828125 L 15.707031 7.1210938 z"></path></svg>
                          </span>
                          <span onClick={() => this.handleDelete(policeStation.id, policeStation.name)}>
                            <svg className="userAction" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M 10.806641 2 C 10.289641 2 9.7956875 2.2043125 9.4296875 2.5703125 L 9 3 L 4 3 A 1.0001 1.0001 0 1 0 4 5 L 20 5 A 1.0001 1.0001 0 1 0 20 3 L 15 3 L 14.570312 2.5703125 C 14.205312 2.2043125 13.710359 2 13.193359 2 L 10.806641 2 z M 4.3652344 7 L 5.8925781 20.263672 C 6.0245781 21.253672 6.877 22 7.875 22 L 16.123047 22 C 17.121047 22 17.974422 21.254859 18.107422 20.255859 L 19.634766 7 L 4.3652344 7 z"></path></svg>
                          </span>
                        </div>
                      </td> 
                  </tr>
                ))}
                </tbody>
              </table>
              <div className="police-station_admin_control_btn" onClick={() => this.handleCreate()}>Add Police Station</div>
              </div>
            </div>
            : <div className="row">
              <div className="col-md-12">
                <div className="alert alert-primary" role="alert">
                  Sorry, this page is not accessible to you. Try logging in.
                </div>
              </div>
            </div>
        }
      </div>
    );
  }
}
