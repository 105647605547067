import React, { useState,useEffect,useRef } from 'react';
import axios from 'axios';
import {uploadInstitutionData} from "../../url"

export default function App(props){
    const [login] = useState(window.localStorage.getItem("loggedin"))
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [validFiles, setValidFiles] = useState([]);
    const [unsupportedFiles, setUnsupportedFiles] = useState([]);
    const [multipleFiles, setmultipleFiles] = useState(false);
    const fileInputRef = useRef();
    const uploadModalRef = useRef();
    const uploadRef = useRef();
    const progressRef = useRef();
    const [uid] = useState(window.localStorage.getItem("uid"))
    const [client] = useState(window.localStorage.getItem("client"))
    const [token] = useState(window.localStorage.getItem("token"))
    const [success,setSuccess] = useState(false)
    const [error,setError] = useState(false)

    useEffect(() => {
        let filteredArray = selectedFiles.reduce((file, current) => {
            const x = file.find(item => item.name === current.name);
            if (!x) {
                return file.concat([current]);
            } else {
                return file;
            }
        }, []);
        setValidFiles([...filteredArray]);
    
    }, [selectedFiles]);

    const dragOver = (e) => {
        e.preventDefault();
    }
    
    const dragEnter = (e) => {
        e.preventDefault();
    }
    
    const dragLeave = (e) => {
        e.preventDefault();
    }
    
    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;

        if (files.length && files.length=== 1) {
            handleFiles(files);
            setmultipleFiles(false)
        }
        else{
            setmultipleFiles(true)
        }
    }

    const validateFile = (file) => {
        const validTypes = ['text/csv','application/csv','application/vnd.ms-excel'];
        if (validTypes.indexOf(file.type) === -1) {
            return false;
        }
        return true;
    }
    const handleFiles = (files) => {
        for(let i = 0; i < files.length; i++) {
            if (validateFile(files[i]) && selectedFiles.length === 0) {
                setSelectedFiles(prevArray => [...prevArray, files[i]]);
            } else {
                files[i]['invalid'] = true;
                setSelectedFiles(prevArray => [...prevArray, files[i]]);
                setErrorMessage('File type not permitted');
                setUnsupportedFiles(prevArray => [...prevArray, files[i]]);
            }
        }
    }

    const fileSize = (size) => {
        if (size === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }

    const fileType = (fileName) => {
        return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
    }

    const removeFile = (name) => {

        const validFileIndex = validFiles.findIndex(e => e.name === name);
        validFiles.splice(validFileIndex, 1);
      
        setValidFiles([...validFiles]);
        const selectedFileIndex = selectedFiles.findIndex(e => e.name === name);
        selectedFiles.splice(selectedFileIndex, 1);
      
        setSelectedFiles([...selectedFiles]);
        const unsupportedFileIndex = unsupportedFiles.findIndex(e => e.name === name);

        if (unsupportedFileIndex !== -1) {
            unsupportedFiles.splice(unsupportedFileIndex, 1);
            setUnsupportedFiles([...unsupportedFiles]);
        }
    }


    
    const filesSelected = () => {
        if (fileInputRef.current.files.length) {
            handleFiles(fileInputRef.current.files);
        }
    }

    const uploadFiles = () => {
        
            const formData = new FormData();
            formData.append('input_file', validFiles[0]);


            axios.post(uploadInstitutionData, formData, {
                headers: {
                    uid:uid,
                    client:client,
                    "Access-Token":token,
                  'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                if(response.status === 204)
                {
                    
                    setSuccess(true)
                    setValidFiles([])
                    setError(false)
                }
                
            })
            .catch(() => {
                setValidFiles([])
                setError(true)
                // If error, display a message on the upload modal
                uploadRef.current.innerHTML = `<span class="error">Error Uploading File(s)</span>`;
                // set progress bar background color to red
                progressRef.current.style.backgroundColor = 'red';
            });
        
    }

    const closeUploadModal = () => {
        uploadModalRef.current.style.display = 'none';
    }

    return (
        <div className="container main-wrapper">
                {
                    login
                    ?
                        <div className="row">
                                <div className="col-md-12">
                                    <h2 className="heading text-Left">Upload <span className="user">Volunteers Data</span></h2>
                                </div>

                                <div className="upload-modal" ref={uploadModalRef}>
                                    <div className="overlay"></div>
                                    <div className="close" onClick={(() => closeUploadModal())}>X</div>
                                    <div className="progress-container">
                                        <span ref={uploadRef}></span>
                                        <div className="progress">
                                            <div className="progress-bar" ref={progressRef}></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-2">
                                        </div>

                                        <div className="col-md-8">
                                            <div className="uploadBox text-center"
                                                onDragOver={dragOver}
                                                onDragEnter={dragEnter}
                                                onDragLeave={dragLeave}
                                                onDrop={fileDrop}
                                            >
                                            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="50px" viewBox="0 0 24 24" width="50px" fill="#000000"><g><rect fill="none" height="24" width="24"/></g><g><g><path d="M14,2H6C4.9,2,4.01,2.9,4.01,4L4,20c0,1.1,0.89,2,1.99,2H18c1.1,0,2-0.9,2-2V8L14,2z M18,20H6V4h7v5h5V20z M8,15.01 l1.41,1.41L11,14.84V19h2v-4.16l1.59,1.59L16,15.01L12.01,11L8,15.01z"/></g></g></svg><br/><br/>
                                                Drag and Drop To upload
                                            </div>
                                        </div>

                                        <input
                                            ref={fileInputRef}
                                            className="file-input"
                                            type="file"
                                            multiple
                                            onChange={filesSelected}
                                        />

                                        <div className="col-md-2">
                                        </div> 
                                        <div className="col-md-2">
                                        </div>

                                        <div className="col-md-8">
                                            <div className="container uploadBtn">

                                            {
                                                unsupportedFiles.length === 0 && validFiles.length 
                                                ? 
                                                    <button className="submit-btn file-upload-btn" onClick={() => uploadFiles()}>Upload Files</button> 
                                                : 
                                                    ''
                                            } 

                                            {
                                                unsupportedFiles.length
                                                ? 
                                                    <p className='file-error-message'>Please remove all unsupported files or remove multiple files</p>
                                                : 
                                                    ''
                                            }
                                            {
                                                multipleFiles
                                                ? 
                                                    <p className='file-error-message'>Multiple files not supported.</p>
                                                : 
                                                    ''
                                            }
                                            {
                                                success
                                                ?
                                                    <div className="alert alert-primary">
                                                        File uploaded Successfully
                                                    </div>   
                                                :
                                                  ""
                                            }
                                            {
                                                error
                                                ?
                                                    <div className="alert alert-danger">
                                                        Something Went wrong. Try again.
                                                    </div>   
                                                :
                                                  ""
                                            }
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                        </div>
                                        <div className="col-md-2">
                                        </div>


                                        <div className="col-md-8">
                                            <div className="file-display-container">
                                            {
                                                validFiles.map((data, i) => 
                                                    <div key={i} className="file-status-bar">
                                                        <div>
                                                            <div className="file-type-logo"></div>
                                                            <div className="file-type">{fileType(data.name)}</div>
                                                            <div className={`file-name ${data.invalid ? 'file-error' : ''}`}>{data.name.slice(0,10)}</div>
                                                            <div className="file-size">({fileSize(data.size)})</div> {data.invalid && <span className='file-error-message'>({errorMessage})</span>}
                                                            <div className="file-remove" onClick={() => removeFile(data.name)}>X</div>
                                                        </div>
                                                        
                                                    </div>
                                                )
                                            }
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                        </div>

                                        <div className="col-md-2">
                                        </div>                                    
                                    </div>
                                </div>
                        </div>
                    :
                        <div className="row">
                            <div className="col-md-12">
                                <div className="alert alert-primary" role="alert">
                                Sorry this page is not accessible to you try logging in.
                                </div>
                            </div>
                        </div>
                 }
            </div>
    );
}