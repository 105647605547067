import React,{Component} from 'react'
import {Link} from 'react-router-dom'
import Verify from '../verify/Verify'
import {
  unverifiedVolunteersTaluk,
  unverifiedInstitutionsTaluk,
  getVillage,
  getTaluks
} from '../../url'
import axios from 'axios';

export default class Dashboard extends Component{
    constructor(props) {
      super(props);

        this.state = {
            login:window.localStorage.getItem("loggedin"),
            iap:window.localStorage.getItem("iap"),
            volunteer:[],
            institution:[],

            villageList:[],
            talukList:[],
            villageListFiltred:[], 

            uid:window.localStorage.getItem("uid"),
            client:window.localStorage.getItem("client"),
            "access-token":window.localStorage.getItem("token"),
        };

        this.getUnverifiedVolunteers = this.getUnverifiedVolunteers.bind(this)
        this.getUnverifiedInstitution = this.getUnverifiedInstitution.bind(this)
        
    }

    componentDidMount(){
      this.getUnverifiedVolunteers() 
      this.getUnverifiedInstitution()
      this.getVillage()
      this.getTaluk()
    }

      
      getVillage(){
        axios.get(getVillage,{
            headers:{
                'Content-Type':'application/json',
            }
        })
        .then(response =>{
            const villages = response.data

            this.setState({
                ...this.state,
                villageList:[...villages]
                
            })
        })
        .catch(error => {
            
        })
    }

    getTaluk(){
        axios.get(getTaluks)
        .then(response => {
            const taluks = response.data

            this.setState({
                ...this.state,
                talukList:[...taluks]
            })
        })
        .catch(error => {
            
        })
    }   

    getUnverifiedVolunteers(){
      axios.get(unverifiedVolunteersTaluk,{
        headers:{
          uid:this.state.uid,
          client:this.state.client,
          "Access-Token":this.state["access-token"],
        }
      })
      .then(response => {
        var volunteer = response.data
        this.setState({
          ...this.state,
          volunteer:[...volunteer]
        })
      })
      .catch(error => {
        
      })
    }
    
    getUnverifiedInstitution(){
      axios.get(unverifiedInstitutionsTaluk,{
        headers:{
          uid:this.state.uid,
          client:this.state.client,
          "Access-Token":this.state["access-token"],
        }
      })
      .then(response => {
        var institution = response.data
        this.setState({
          ...this.state,
          institution:[...institution]
        })
      })
      .catch(error => {
        
      })
    }

    render(){
        return(
           
            <div className="container secondary-wrapper">
              {
                this.state.login && this.state.iap === "y9HUXikv2ka8s7/naBPysg=="
                ?
                <div>
                  <div className="row">
                    <div className="col-md-12">
                        <h2 className="heading text-left">Welcome <span className="user">Police Station Admin</span></h2>
                    </div>
                    
                    <div className="col-lg-4">
                      <Link className="link" to="/volunteers/search">
                        <div className="box">
                        <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="36px" viewBox="0 0 24 24" width="36px" fill="#000000"><g><rect fill="none" height="24" width="24"/></g><g><g><path d="M10,12c2.21,0,4-1.79,4-4c0-2.21-1.79-4-4-4S6,5.79,6,8C6,10.21,7.79,12,10,12z M10,6c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2 S8,9.1,8,8C8,6.9,8.9,6,10,6z"/><path d="M4,18c0.22-0.72,3.31-2,6-2c0-0.7,0.13-1.37,0.35-1.99C7.62,13.91,2,15.27,2,18v2h9.54c-0.52-0.58-0.93-1.25-1.19-2H4z"/><path d="M19.43,18.02C19.79,17.43,20,16.74,20,16c0-2.21-1.79-4-4-4s-4,1.79-4,4c0,2.21,1.79,4,4,4c0.74,0,1.43-0.22,2.02-0.57 c0.93,0.93,1.62,1.62,2.57,2.57L22,20.59C20.5,19.09,21.21,19.79,19.43,18.02z M16,18c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2 C18,17.1,17.1,18,16,18z"/></g></g></svg><br/>
                            Search For Volunteer
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-4">
                      <Link className="link" to="/institutions/search">
                        <div className="box">
                        <svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 0 24 24" width="236px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z"/></svg><br/>
                            Search For Institution
                        </div>
                      </Link>
                    </div>


                    {/* <div className="col-lg-4">
                      <Link className="link" to="/taluk/invite/village">
                        <div className="box">
                          <svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 0 24 24" width="36px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"/></svg><br/>
                            Add Village Admin
                        </div>
                      </Link>
                    </div>

                    <div className="col-lg-4">
                      <Link className="link" to="/taluk/invite/user">
                        <div className="box">
                          <svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 0 24 24" width="36px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"/></svg><br/>
                            Add User
                        </div>
                      </Link>
                    </div> */}

                    <div className="col-lg-4">
                      <Link className="link" to="/volunteers/registration">
                        <div className="box">
                        <svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 0 24 24" width="36px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm0 8c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4zm-6 4c.22-.72 3.31-2 6-2 2.7 0 5.8 1.29 6 2H9zm-3-3v-3h3v-2H6V7H4v3H1v2h3v3z"/></svg><br/>
                            Add a Volunteer
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-4">
                      <Link className="link" to="/institutions/registration">
                        <div className="box">
                        <svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 0 24 24" width="36px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm0 8c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4zm-6 4c.22-.72 3.31-2 6-2 2.7 0 5.8 1.29 6 2H9zm-3-3v-3h3v-2H6V7H4v3H1v2h3v3z"/></svg><br/>
                            Register an Institution
                        </div>
                      </Link>
                    </div>
                    
                    {/* <div className="col-lg-4">
                      <Link className="link" to="/volunteers/upload">
                        <div className="box">
                        <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="36px" viewBox="0 0 24 24" width="36px" fill="#000000"><g><rect fill="none" height="24" width="24"/></g><g><g><path d="M14,2H6C4.9,2,4.01,2.9,4.01,4L4,20c0,1.1,0.89,2,1.99,2H18c1.1,0,2-0.9,2-2V8L14,2z M18,20H6V4h7v5h5V20z M8,15.01 l1.41,1.41L11,14.84V19h2v-4.16l1.59,1.59L16,15.01L12.01,11L8,15.01z"/></g></g></svg><br/>
                            Upload Volunteer Data
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-4">
                      <Link className="link" to="/institutions/upload">
                        <div className="box">
                        <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="36px" viewBox="0 0 24 24" width="36px" fill="#000000"><g><rect fill="none" height="24" width="24"/></g><g><g><path d="M14,2H6C4.9,2,4.01,2.9,4.01,4L4,20c0,1.1,0.89,2,1.99,2H18c1.1,0,2-0.9,2-2V8L14,2z M18,20H6V4h7v5h5V20z M8,15.01 l1.41,1.41L11,14.84V19h2v-4.16l1.59,1.59L16,15.01L12.01,11L8,15.01z"/></g></g></svg><br/>
                            Upload Institution Data
                        </div>
                      </Link>
                    </div> */}
                    
                    <div className="col-lg-4">
                      <Link className="link" to="/password/change">
                        <div className="box">
                          <svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 0 24 24" width="36px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"/></svg><br/>
                            Change Password
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                        <h2 className="heading text-left">Verify <span className="user">Users</span></h2>
                    </div>
                      {
                        
                        this.state.volunteer.length === 0 
                        ?
                          <div className="col-md-4">
                            <div>
                              No user to verify
                            </div>
                          </div>
                        :
                          this.state.volunteer.map(volunteer => (
                            <Verify
                              key={volunteer.id}
                              type="User"
                              data={volunteer}
                              village = {this.state.villageList.find(village => village.id === volunteer.village_id)}
                              rerender={this.getUnverifiedVolunteers}
                            />
                          ))
                      }

                    <div className="col-md-12">
                        <h2 className="heading text-left">Verify <span className="user">Institutions</span></h2>
                    </div>
                      {
                        
                        this.state.institution.length === 0 
                        ?
                          <div className="col-md-4">
                            <div>
                              No institutions to verify
                            </div>
                          </div>
                        :
                          this.state.institution.map(institution => (
                            <Verify
                              key={institution.id}
                              type="Institution"
                              data={institution}
                              village = {this.state.villageList.find(village => village.id === institution.village_id)}
                              rerender={this.getUnverifiedInstitution}
                            />
                          ))
                      }

                    </div>
                  

                  {/* <div className="row">
                    <div className="col-md-12">
                        <h2 className="heading text-left">User <span className="user">Summary</span></h2>
                    </div>
                    <div className="col-lg-4 heading">
                      <span className="text-left data-title">No. of Users</span><br/>
                      
                    </div>
                    <div className="col-lg-4 heading">
                      <span className="text-left data-title">New Users</span><br/>
                      
                    </div>
                    <div className="col-lg-4 heading">
                      <span className="text-left data-title">Users for updation</span><br/>
                      
                    </div>
                  </div> */}
                </div>
                :
                  <div className="row">
                    <div className="col-md-12">
                      <div className="alert alert-primary" role="alert">
                        Sorry this page is not accessible to you try logging in.
                      </div>
                    </div>
                  </div>
              }
        </div>
        );
    }
}