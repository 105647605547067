import React, {Component} from 'react'
import {getAllUser} from  '../../url'
import axios from 'axios';

export default class EditAdmin extends Component{
    constructor(props){
        super(props);

        this.state = {
            selectedTab:0,
            login:window.localStorage.getItem("loggedin"),
            iap:window.localStorage.getItem("iap"),
            uid:window.localStorage.getItem("uid"),
            client:window.localStorage.getItem("client"),
            "access-token":window.localStorage.getItem("token"),
        }

        this.handleTabChange = this.handleTabChange.bind(this)
    }

    componentDidMount(){
        axios.get(getAllUser,{
            headers:{
                uid:this.state.uid,
                client:this.state.client,
                "Access-Token":this.state["access-token"],
            }
        })
        .then(response => {
            console.log("yup",response);
        })
    }

    handleTabChange(tabNo){
        this.setState({
            selectedTab:tabNo
        })
    }
    render(){
        console.log(this.state);
        return(
            <div className="container">
                {
                    this.state.login && this.state.iap === "0b9x3JGsD0uBvV1zA+vktQ=="
                    ?
                    <>
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="heading text-center">Edit<span className="user">Admin</span></h2>
                            </div>
                        </div>

                        <div className="row">
                            {
                                this.state.selectedTab === 0
                                ?
                                    <div className="col-md-4 col-sm-4 edit-admin-tabs selected" onClick={()=>this.handleTabChange(0)}>
                                        <div className="mobile-selected">
                                            Taluk Admins
                                        </div>
                                    </div>
                                :
                                    <div className="col-md-4 col-sm-4 edit-admin-tabs" onClick={()=>this.handleTabChange(0)}>
                                        <div>
                                            Taluk Admins
                                        </div>
                                    </div>
                            }
                            {
                                this.state.selectedTab === 1
                                ?
                                    <div className="col-md-4 col-sm-4 edit-admin-tabs selected" onClick={()=>this.handleTabChange(1)}>
                                        <div className="mobile-selected">
                                            Village Admins
                                        </div>
                                    </div>
                                :
                                    <div className="col-md-4 col-sm-4 edit-admin-tabs" onClick={()=>this.handleTabChange(1)}>
                                        <div>
                                            Village Admins
                                        </div>
                                    </div>
                            }
                            {
                                this.state.selectedTab === 2
                                ?
                                    <div className="col-md-4 col-sm-4 edit-admin-tabs selected" onClick={()=>this.handleTabChange(2)}>
                                        <div className="mobile-selected">
                                            Users
                                        </div>
                                    </div>
                                :
                                    <div className="col-md-4 col-sm-4 edit-admin-tabs" onClick={()=>this.handleTabChange(2)}>
                                        <div>
                                            Users
                                        </div>
                                    </div>
                            }


                            
                                                                 
                        </div> 

                        <div className="row">
                            <div className="col-md-4">
                                test
                            </div>
                            <div className="col-md-4">
                                test
                            </div>
                            <div className="col-md-4">
                                test
                            </div>
                        </div>                      

                    </>
                    :
                        <div className="row">
                            <div className="col-md-12">
                                <div className="alert alert-primary" role="alert">
                                    Sorry this page is not accessible to you try logging in.
                                </div>
                            </div>
                        </div>
                }

            </div>
        );
    }
}