import notfound from '../images/notfound.webp'

function NotFound(props) {
    return (
        <div className="container">
            <div className="Row">
                
                <div className="col-md-12 notfound-wrapper">
                    <span className="notfound">404</span><br/> Page <span className="notfound-text">Not Found</span>
                </div> 

                <div className="col-md-12 notfound-wrapper">
                    <img src={notfound} className="notfoundImage" alt={"Reach Not found"}/>
                </div> 
            </div>
        </div>
    );
}

export default NotFound;