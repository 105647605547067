import React,{Component} from 'react'
import copy from "copy-to-clipboard";

export default class InstitutionCard extends Component{
    constructor(props){
        super(props);
        
        this.state = {
            showAddress: false,
            copied:false,
            showClipBoardMessage:false,
        }

        this.showAdditionalDetails = this.showAdditionalDetails.bind(this)
        this.copyDetails = this.copyDetails.bind(this)
        this.handelHover = this.handelHover.bind(this)
        this.handelHoverOver = this.handelHoverOver.bind(this)
    }

    showAdditionalDetails(event){
        event.preventDefault()

        this.setState(prevState => ({
            showAddress: !prevState.showAddress
        }))
    }

    handelHover(event){
        event.preventDefault()
        this.setState({
            ...this.state,
            showClipBoardMessage:true
        })

    }

    handelHoverOver(event){
        event.preventDefault()
        this.setState({
            ...this.state,
            showClipBoardMessage:false,
        })

    }


    copyDetails(event){
        event.preventDefault()
        
        this.setState({
            ...this.state,
            showClipBoardMessage:false,
            copied:true
        })

        setTimeout(
            () => this.setState({ copied: false}), 
            2000
          );

        const details = 
        "Name: "+this.props.data.name+"\r\n\r\n"
        // +"Phone: +91"+this.props.data.phone+"\r\n\r\n"
        // +"Resource: "
        // +this.props.data.resources.map(data =>(
        //     "\r\n"+data.resource_type
        // ))

        window.encodeURIComponent(details)

        copy(details);
        

    }


    render(){
        return(
              <div className="card-container">
                <h5>{this.props.data.name} </h5>
                <p>{this.props.data.institution_type}</p>
                
                <div>
                    <a href={"tel:+91"+ this.props.data.phone1}>
                        <span className="userAction">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M6.54 5c.06.89.21 1.76.45 2.59l-1.2 1.2c-.41-1.2-.67-2.47-.76-3.79h1.51m9.86 12.02c.85.24 1.72.39 2.6.45v1.49c-1.32-.09-2.59-.35-3.8-.75l1.2-1.19M7.5 3H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.49c0-.55-.45-1-1-1-1.24 0-2.45-.2-3.57-.57-.1-.04-.21-.05-.31-.05-.26 0-.51.1-.71.29l-2.2 2.2c-2.83-1.45-5.15-3.76-6.59-6.59l2.2-2.2c.28-.28.36-.67.25-1.02C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1z"/></svg>
                        </span>
                    </a>

                    <a 
                    href={
                            "whatsapp://send?text=*Name:* "+this.props.data.name+" "
                            // +"%0a%0a*Phone:* +91"+this.props.data.phone
                            // +"%0a%0a*Address:* %0a"+this.props.data.address
                            // // +"%0a%0a*Village:* "+this.props.village.name
                            // +"%0a%0a*Resources:* "+
                            // this.props.data.resources.map(data =>(
                            //     "%0a"+data.resource_type
                            // ))
                            // +"%0a%0a*Blood Group:* "+
                            //     (this.props.data.blood_group 
                            //     ?
                            //         this.props.data.blood_group
                            //     :
                            //         "Not Provided")
                            
                        } 
                    data-action="share/whatsapp/share">
                        

                        <span className="userAction" >
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92c0-1.61-1.31-2.92-2.92-2.92zM18 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM6 13c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm12 7.02c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"/></svg>
                        </span>
                    </a>
                    {
                        this.state.showClipBoardMessage && (this.state.copied === false)
                        ?
                            <span className="copy-to-clipboard">Copy To clipboard</span>
                        :
                            <span></span>

                    }
                    {
                        this.state.copied
                        ?
                            <span className="copy-to-clipboard">Copied</span>
                        :
                            <span></span>

                    }
                    
                    <span className="userAction" onClick={this.copyDetails} onMouseEnter={this.handelHover} onMouseLeave={this.handelHoverOver}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg>
                    </span>

                    <span className="userAction" onClick={this.showAdditionalDetails}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/></svg>
                    </span>

                    
                </div>

                {
                    this.state.showAddress
                    ?
                        <div className="additional-details text-left">

                            <h6>Address</h6>
                            <p className="address">{this.props.data.address},</p>
                            {
                                this.props.village
                                ?
                                    <span  className="address">{this.props.village.name}, </span>
                                :
                                <div></div>
                            }
                            {
                                this.props.taluk
                                ?
                                    <span className="address">{this.props.taluk.name}</span>
                                :
                                <div></div>
                            }                            
                            <p>{this.props.data.pincode}</p>                        

                            <h6>Phone no.</h6>
                            <a href={"tel:+91"+ this.props.data.phone1}> {this.props.data.phone1}</a>
                            {this.props.data.phone2
                                ?
                                 <a href={"tel:+91"+ this.props.data.phone2}>, {this.props.data.phone2}</a>
                                :
                                <a href={"tel:+91"+ this.props.data.phone2}>{this.props.data.phone2} </a> 
                            }
                            
                        </div>
                    :
                        <div>
                        </div>
                }

                <div className="skills">
                  <h6>Type</h6>
                  <ul>
                    <li>{this.props.data.authority_type}</li>
                  </ul>
                </div>
              </div>
        )
    }
}