/******************************************************************
                        Global
*******************************************************************/
const baseURL =  `https://api.traumareach.com/api/v1/`

/******************************************************************
                    Authentication/Validation
*******************************************************************/
 
export const signIn = `${baseURL}auth/sign_in`;
 
export const signOut = `${baseURL}auth/sign_out`;
 
export const validateToken = `${baseURL}auth/validate_token`;

/******************************************************************
                        Password
*******************************************************************/
 
export const updatePasswordDashboard = `${baseURL}auth`;
 
export const forgotPassword = `${baseURL}auth/password`;
 
export const changePassword= `${baseURL}auth/password`;

// Redirect after forgot password LocalHost
export const redirectForgotPasswordUrl = `http://reach-frontend.saakhi.org.in/password/set`;
 
export const inviteAdmin = `${baseURL}auth/invitation`;
 
export const setnewpassword = `${baseURL}auth/password`;

/******************************************************************
                        Initial data
*******************************************************************/
 
export const volunteerResource = `${baseURL}assets`
 
export const getTaluks = `${baseURL}taluks`
 
export const getVillage = `${baseURL}villages`
 
export const getPanchayat = `${baseURL}panchayaths`

export const getPoliceStation = `${baseURL}police_stations`
 
export const institutionType = `${baseURL}institutions/institution_types`
 
export const authorityType = `${baseURL}institutions/authority_types`

/******************************************************************
                        Search Volunteer
*******************************************************************/
 
export const searchVolunteer =`${baseURL}volunteers/search`
 
export const getVolunteerInitialData =`${baseURL}volunteers/list`
export const getVolunteerArchivedData =`${baseURL}volunteers/archived`
 
export const searchInstitution =`${baseURL}institutions/search`

export const getInstitutionInitialData =`${baseURL}institutions/list`

export const getVolunteerDetails = `${baseURL}volunteers/`

export const archiveVolunteerID = `${baseURL}volunteers/`

export const unarchiveVolunteerID = `${baseURL}volunteers/`

export const deleteVolunteerID = `${baseURL}volunteers/`

/******************************************************************
                        Registration
*******************************************************************/

export const volunteerRegistration = `${baseURL}volunteers`

export const institutionRegistration = `${baseURL}institutions`

/******************************************************************
                        Verify
*******************************************************************/
export const unverifiedVolunteersTaluk = `${baseURL}volunteers/unverified`

export const unverifiedInstitutionsTaluk = `${baseURL}institutions/unverified`

export const unverifiedVolunteersVillage = `${baseURL}volunteers/unverified`

export const unverifiedInstitutionsVillage = `${baseURL}institutions/unverified`

export const verifyVolunteers = `${baseURL}volunteers/`

export const archiveVolunteers = `${baseURL}volunteers/`

export const verifyInstitutions = `${baseURL}institutions/`

export const archiveInstitutions = `${baseURL}institutions/`

export const uploadVolunteerData = `${baseURL}imports/volunteers`

export const uploadInstitutionData = `${baseURL}imports/institutions`

/******************************************************************
                        Edit user
*******************************************************************/
export const getAllUser = `${baseURL}users`
