import React,{Component} from 'react';
import axios from 'axios';
import {signOut} from '../url'

export default class Nav extends Component{
    constructor(props) {
        super(props);
    
        this.state = {
        };
    }
    
    componentDidMount(){
        this.setState({
            login: window.localStorage.getItem("loggedin"),
            role: window.localStorage.getItem("role"),
            email: window.localStorage.getItem("email"),
            uid:window.localStorage.getItem("uid"),
            client:window.localStorage.getItem("client"),
            "access-Token":window.localStorage.getItem("token"),
        })

        
    }


    

    handleLogout = () => {
        axios.delete(signOut,{
            headers:{
                ...this.state
            }
        })
        .then((response) =>{
            if(response.statusText === 'OK')
            {
                window.localStorage.clear()
                window.location='/login';
            }
        })
        .catch(error =>{
             
        })
    }

    UserRender = (props) => {
        const role = props.role;
            if (role === "superadmin") {
                return (
                    <a className="nav-link" href="/admin/dashboard">Dashboard </a>
                )
            } 
            else if (role === "regional_admin") {
                return (
                    <a className="nav-link" href="/taluk/dashboard">Dashboard </a>
                )
            } 
            else if (role === "area_admin") {
                return (
                    <a className="nav-link" href="/village/dashboard">Dashboard</a>
                )
            }
            else if (role === "normal_user") {
                return (
                    <a className="nav-link" href="/user/dashboard">Dashboard</a>
                )
            }
            else{
                return (
                    <a className="nav-link" href="/">Guest</a>
                )
            }
    }

    render(){
        return(
            
            <nav className="navbar navbar-expand-lg navbar-light bg-light">

                <a className="navbar-brand" href="/">Trauma Reach</a>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">

                    {
                        this.state.email === null
                        ?
                            <ul className="nav ">
                                <li className="nav-item">
                                    <a className="nav-link" href="/">Home</a>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link" href="/volunteers/registration">Volunteer</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/institutions/registration">Institution</a>
                                </li> */}
                                <li className="nav-item">
                                    <a className="nav-link" href="/login">Login</a>
                                </li>
                            </ul>
                        :
                            <ul className="nav ">
                                <li className="nav-item">
                                    <span className="nav-link">{this.state.email}</span>
                                </li>
                                <li className="nav-item">

                                
                                    {
                                        this.state.login
                                        ?
                                            <this.UserRender role={this.state.role}/>
                                        :
                                            <a className="nav-link" href="/">Guest </a>
                                    }
                                    
                                </li>

                                <li className="nav-item">
                                    <span className="nav-link link-color" onClick={this.handleLogout}>Logout</span>
                                </li>
                            </ul>

                    }
                    

                    

                </div>
                
            </nav>
        );
    }
}