import React,{Component} from 'react';
import {Link} from 'react-router-dom';

export default class Dashboard extends Component{
    constructor(props) {
      super(props);

        this.state = {
            login:window.localStorage.getItem("loggedin"),
            iap:window.localStorage.getItem("iap"),
        };
    }

    
    render(){
        return(
           
            <div className="container secondary-wrapper">
              {
                this.state.login && this.state.iap === "hToyr+zlf0Sm7KV5iz8OtA=="
                ?
                <div>
                  <div className="row">
                    <div className="col-md-12">
                        <h2 className="heading text-left">Welcome <span className="user">User</span></h2>
                    </div>
                    
                    <div className="col-lg-4">
                      <Link className="link" to="/volunteers/search">
                        <div className="box">
                        <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="36px" viewBox="0 0 24 24" width="36px" fill="#000000"><g><rect fill="none" height="24" width="24"/></g><g><g><path d="M10,12c2.21,0,4-1.79,4-4c0-2.21-1.79-4-4-4S6,5.79,6,8C6,10.21,7.79,12,10,12z M10,6c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2 S8,9.1,8,8C8,6.9,8.9,6,10,6z"/><path d="M4,18c0.22-0.72,3.31-2,6-2c0-0.7,0.13-1.37,0.35-1.99C7.62,13.91,2,15.27,2,18v2h9.54c-0.52-0.58-0.93-1.25-1.19-2H4z"/><path d="M19.43,18.02C19.79,17.43,20,16.74,20,16c0-2.21-1.79-4-4-4s-4,1.79-4,4c0,2.21,1.79,4,4,4c0.74,0,1.43-0.22,2.02-0.57 c0.93,0.93,1.62,1.62,2.57,2.57L22,20.59C20.5,19.09,21.21,19.79,19.43,18.02z M16,18c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2 C18,17.1,17.1,18,16,18z"/></g></g></svg><br/>
                            Search For Volunteer
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-4">
                      <Link className="link" to="/institutions/search">
                        <div className="box">
                        <svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 0 24 24" width="236px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z"/></svg><br/>
                            Search For Institution
                        </div>
                      </Link>
                    </div>
                    
                    <div className="col-lg-4">
                      <Link className="link" to="/volunteers/registration">
                        <div className="box">
                        <svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 0 24 24" width="36px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm0 8c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4zm-6 4c.22-.72 3.31-2 6-2 2.7 0 5.8 1.29 6 2H9zm-3-3v-3h3v-2H6V7H4v3H1v2h3v3z"/></svg><br/>
                            Register a Volunteer
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-4">
                      <Link className="link" to="/institutions/registration">
                        <div className="box">
                        <svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 0 24 24" width="36px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm0 8c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4zm-6 4c.22-.72 3.31-2 6-2 2.7 0 5.8 1.29 6 2H9zm-3-3v-3h3v-2H6V7H4v3H1v2h3v3z"/></svg><br/>
                            Register an Institution
                        </div>
                      </Link>
                    </div>

                    <div className="col-lg-4">
                      <Link className="link" to="/password/change">
                        <div className="box">
                          <svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 0 24 24" width="36px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"/></svg><br/>
                            Change Password
                        </div>
                      </Link>
                    </div>
                  </div>

                </div>
                :
                  <div className="row">
                    <div className="col-md-12">
                      <div className="alert alert-primary" role="alert">
                        Sorry this page is not accessible to you try logging in.
                      </div>
                    </div>
                  </div>
              }
        </div>
        );
    }
}