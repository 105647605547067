import React,{Component} from 'react';
import {
    inviteAdmin,
    getTaluks,
    getVillage,
} from '../../url';
import axios from 'axios';

export default class InviteTalukAdmin extends Component{
    constructor(props) {
        super(props);
  
          this.state = {
              login:window.localStorage.getItem("loggedin"),
              iap:window.localStorage.getItem("iap"),
              email:"",
              taluk:"",
              village:"",
              role:"area_admin",
              loading:false,
              error:null,
              info:null,

              talukList:[],
              villageList:[],
              villageListFiltred:[],

              uid:window.localStorage.getItem("uid"),
              client:window.localStorage.getItem("client"),
              "access-token":window.localStorage.getItem("token"),
              
          };

          this.handleChange = this.handleChange.bind(this);
          this.handleTalukChange = this.handleTalukChange.bind(this);
          this.handleSubmit = this.handleSubmit.bind(this);
      }

   

    componentDidMount(){
        axios.get(getTaluks)
        .then(response => {
            var taluk = response.data

            this.setState({
                talukList:[...taluk]
            })
        })
        .catch(error => {
             
        })

        axios.get(getVillage)
        .then(response => {
            var villages = response.data

            this.setState({
                villageList:[...villages]
            })
        })
        .catch(error => {
             
        })

    }

    handleChange(event) {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
        });
    }

    handleTalukChange(event){
        this.setState(prevState => ({
            [event.target.name]: event.target.value,
            villageListFiltred : prevState.villageList.filter(village => village["taluk_id"] === event.target.value)
        }))
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            ...this.state,
            loading:true
        })

         
        axios({
            method: "post",
            url: inviteAdmin,
            data: {
                email:this.state.email,
                village_id:this.state.village,
                taluk_id:this.state.taluk,
                role:this.state.role 
            },
            headers: {
                uid:this.state.uid,
                client:this.state.client,
                "Access-Token":this.state["access-token"],
            }
        })
        .then(response => {

            this.setState({
                ...this.state,
                info:response.data.success,
                loading:false,
                email:"",
                village:"",
                taluk:"",
            })
            setTimeout(function(){
                this.setState({
                    info:null,
                    error:null,
                    loading:false,
                })
            }.bind(this),3000)

        })
        .catch(error => {
            if (error.response) {
                this.setState({
                    ...this.state,
                    loading:false,
                    error: "Something went wrong. User might already Exist"
                })
                setTimeout(function(){
                    this.setState({
                        info:null,
                        error:null,
                        loading:false,
                    })
                }.bind(this),3000)
              } else if (error.request) {
                  this.setState({
                      ...this.state,
                      loading:false,
                      error: "Something went wrong"
                  })
                  setTimeout(function(){
                    this.setState({
                        info:null,
                        error:null,
                        loading:false,
                    })
                }.bind(this),3000)
              } 
        })
    }

    render(){
        return(
            <div className="container main-wrapper">
                {
                this.state.login && this.state.iap === "0b9x3JGsD0uBvV1zA+vktQ=="
                ?
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="heading text-center">Add <span className="user">Village Admin</span></h2>
                    </div>
                    <div className="col-md-3">
                </div>
                    <div className="col-md-6">
                    {
                        this.state.error
                        ?
                            <div className="alert alert-danger">
                                 {this.state.error}   
                            </div>   
                        :
                            
                            <div></div>
                    }
                    {
                        this.state.info
                        ?
                            <div className="alert alert-primary">
                                {this.state.info}
                            </div>
                        :
                            
                             <div></div>
                    }
                    <svg xmlns="http://www.w3.org/2000/svg" height="60px" viewBox="0 0 24 24" width="60px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"/></svg>
                        <form onSubmit={this.handleSubmit} className="form secondary-form">

                            <div className="form-field">
                                <input type="email" name='email' placeholder=" " autofill="off" required 
                                value={this.state.email} onChange={this.handleChange}/>
                                <span className="label">Email</span>
                            </div>

                            <div className="form-field">
                                <select type="" name='taluk' placeholder=" " autofill="off" required 
                                value={this.state.taluk} onChange={this.handleTalukChange}>
                                    <option value={""} disabled={true}>Select Taluk</option>

                                    {
                                        this.state.talukList.map(region => (
                                            <option  key={region.id} value={region.id}>{region.name}</option>
                                        ))
                                    }                            
                                </select>
                                <span className="select-label" >Taluk</span>
                            </div>

                            {
                                this.state.taluk
                                ?
                                    <div className="form-field">
                                        <select type="" name='village' placeholder=" " autofill="off" required 
                                            value={this.state.village} onChange={this.handleChange}>
                                                <option value={""} disabled={true}>Select Village</option>

                                                {
                                                    this.state.villageListFiltred.map(region => (
                                                        <option  key={region.id} value={region.id}>{region.name}</option>
                                                    ))
                                                }                                
                                        </select>
                                        
                                        <span className="select-label" >Village</span>
                                    </div>
                                :
                                    <div className="form-field disabled">
                                        <input type="text" placeholder=" " autofill="off" required 
                                        disabled={true} className="disabled"/>
                                        <span className="label disabled">Select Village</span>
                                    </div>
                            }


                            {
                                this.state.loading
                                ?
                                <div className="container">
                                    <div className="loader-button">
                                        <div className="spinner-border text-light" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="container">
                                    <button className="submit-btn" href="#">Add Admin</button>
                                </div>
                            }

                        </form>
                    </div>
                </div>
                :
                  <div className="row">
                    <div className="col-md-12">
                      <div className="alert alert-primary" role="alert">
                        Sorry this page is not accessible to you try logging in.
                      </div>
                    </div>
                  </div>
              }
            </div>
        )
    }
}