import React, { Component } from 'react';
import { volunteerResource } from '../../url';
import axios from 'axios';

export default class InviteNormalUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      login: window.localStorage.getItem("loggedin"),
      editey: false,
      resourceList: [],
      loading: false,
      error: null,
      info: null,
      uid: window.localStorage.getItem("uid"),
      client: window.localStorage.getItem("client"),
      "access-token": window.localStorage.getItem("token"),
    };

    this.getInitialData = this.getInitialData.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      loading: true,
    });

    this.getInitialData();
  }

  getInitialData() {
    axios.get(
      volunteerResource,
      {
        headers: {
          uid: this.state.uid,
          client: this.state.client,
          "Access-Token": this.state["access-token"],
          'Content-Type': 'application/json',
        },
      }
    )
      .then(response => {
        const volunteerResources = response.data;
  
        this.setState({
          ...this.state,
          resourceList: [...volunteerResources],
        });
      })
      .catch(error => {
        console.error(error);
      });
  }  

  handleEdit(id, asset) {
    let name = prompt("Enter Updated Name of Resource: " + asset, asset);
    console.log(name);
    axios.put(`${volunteerResource}/${id}`,
            {
                "asset_type": name,                   
           },
           {
            headers: {
              uid: this.state.uid,
              client: this.state.client,
              "Access-Token": this.state["access-token"],
              'Content-Type': 'application/json',
            }})
           .then(response => {
              console.log(response); 
              this.getInitialData();              
           })
           .catch((error) => {
               console.log(error)
           })
  }

  handleDelete(id, asset) {
    let person = prompt("Deleting will permanently erase the resource "+asset+"\nType 'confirm' to delete permanently", "no");
    if(person != null){
        if(person.toLowerCase() === "confirm"){
          axios.delete(
            `${volunteerResource}/${id}`,
            {
              headers: {
                uid: this.state.uid,
                client: this.state.client,
                "access-token": this.state["access-token"],
                'Content-Type': 'application/json',
              },
            }
          )
            .then(response => {
              console.log("deleted");
              this.getInitialData();
            })
            .catch((error) => {
              console.log(error);
            });    
        }
    } 
    else{
        console.log('cancelled')
    }
  }

  handleCreate() {
    // const date = new Date().toJSON();
    let resource = prompt("Enter the name of the new resource to be added");
    if(resource != null){
      axios.post(
        volunteerResource,
        {
          "asset_type": resource,
        },
        {
          headers: {
            uid: this.state.uid,
            client: this.state.client,
            "Access-Token": this.state["access-token"],
            'Content-Type': 'application/json',
          },
        }
      )
        .then(response => {
          console.log(response);
          this.getInitialData();
        })
        .catch((error) => {
          console.log(error)
        });      
    }
    else{
        console.log('cancelled')
    }
  }

  render() {
    return (
      <div className="container main-wrapper">
        {
          this.state.login
            ? <div className="row resource_admin">
              <div className="col-md-12">
                <h2 className="heading text-center">Resource <span className="user">Admin Control</span></h2>
              </div>
              <div className='resource_admin'>
                <div className='resource_admin_flex'>
                  {this.state.resourceList.map(resource => (
                    <div className='resource_admin_row' key={resource.id}>
                      <p>{resource.asset_type}</p>
                      <div className='resource_admin_crud'>
                      <span onClick={() => this.handleEdit(resource.id, resource.asset_type)}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /> <path d="M18.414062 2 C 18.158062 2 17.902031 2.0979687 17.707031 2.2929688 L 15.707031 4.2929688 L 14.292969 5.7070312 L 3 17 L 3 21 L 7 21 L 21.707031 6.2929688 C 22.098031 5.9019687 22.098031 5.2689063 21.707031 4.8789062 L 19.121094 2.2929688 C 18.926094 2.0979687 18.670063 2 18.414062 2 z M 18.414062 4.4140625 L 19.585938 5.5859375 L 18.292969 6.8789062 L 17.121094 5.7070312 L 18.414062 4.4140625 z M 15.707031 7.1210938 L 16.878906 8.2929688 L 6.171875 19 L 5 19 L 5 17.828125 L 15.707031 7.1210938 z"></path></svg>
                      </span>
                      <span onClick={() => this.handleDelete(resource.id, resource.asset_type)}>
                        <svg className="userAction" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M 10.806641 2 C 10.289641 2 9.7956875 2.2043125 9.4296875 2.5703125 L 9 3 L 4 3 A 1.0001 1.0001 0 1 0 4 5 L 20 5 A 1.0001 1.0001 0 1 0 20 3 L 15 3 L 14.570312 2.5703125 C 14.205312 2.2043125 13.710359 2 13.193359 2 L 10.806641 2 z M 4.3652344 7 L 5.8925781 20.263672 C 6.0245781 21.253672 6.877 22 7.875 22 L 16.123047 22 C 17.121047 22 17.974422 21.254859 18.107422 20.255859 L 19.634766 7 L 4.3652344 7 z"></path></svg>
                      </span>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="admin_control_btn" onClick={() => this.handleCreate()}>Add Resource</div>
                </div>
            </div>
            : <div className="row">
              <div className="col-md-12">
                <div className="alert alert-primary" role="alert">
                  Sorry, this page is not accessible to you. Try logging in.
                </div>
              </div>
            </div>
        }
      </div>
    );
  }
}
