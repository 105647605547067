import React,{Component} from 'react'
import {BrowserRouter as Router, Route,Redirect,Switch} from 'react-router-dom'
import './App.css';

import Nav from './components/nav'
import NotFound from './components/NotFound'
import {validateToken} from './url'
import { Toaster } from 'react-hot-toast';


// Super Admin imports
import Landing from './components/Landing'
import Login from './components/Login'
import Dashboard from './components/superAdmin/Dashboard'
import ForgotPassword from './components/ForgotPassword'
import ChangePassword from './components/superAdmin/ChangePassword'
import NewPassword from './components/NewPassword'
import SetNewPassword from './components/SetNewPassword'
import InviteTalukAdmin from './components/superAdmin/InviteTalukAdmin'
import InviteVillageAdmin from './components/superAdmin/InviteVillageAdmin'
import EditUser from './components/editUser/EditUser';
import ResourceAdminControl from './components/superAdmin/ResourceAdminControl';
import PoliceStationAdminControl from './components/superAdmin/PoliceStationAdminControl';


// Regional admin imports
import RegionalDashboard from './components/regionalAdmin/Dashboard'
import RegionalInviteVillageAdmin from './components/regionalAdmin/RegionalInviteVillageAdmin'

// Area admin imports
import AreaDashboard from './components/areaAdmin/Dashboard'

// Registration imports
import VolunteerRegistraion from './components/registration/VolunteerRegistraion'
import InstitutionalRegistraion from './components/registration/InstitutionalRegistration'
// Normal user
import UserDashboard from './components/normalUser/Dashboard'
import InviteNormalUser from './components/normalUser/InviteNormalUser'

// Global imports
import SearchVolunteer from './components/search/SearchVolunteer'
import SearchInstitution from './components/search/SearchInstitutions'
import UploadInstitution from './components/Upload/UploadInstitution'
import UploadVolunteer from './components/Upload/UploadVolunteer'

import Map from './components/map'

import axios from 'axios'
import EditVolunteer from './components/search/EditVolunteer';

export default class App extends Component{
  constructor(props) {
    super(props);

      this.state = {
          session:window.sessionStorage.getItem("session"),
          role:window.localStorage.getItem("role"),
          login:window.localStorage.getItem("loggedin"),
          uid:window.localStorage.getItem("uid"),
          client:window.localStorage.getItem("client"),
          "access-token":window.localStorage.getItem("token"),
          
      };
  }

  componentDidMount()
  {   
    if(this.state.login && !this.state.session )
    {
      axios.get(validateToken,{
        headers:{
            uid:this.state.uid,
            client:this.state.client,
            "access-token":this.state["access-token"],
        }
      })
      .then(() => {
        window.sessionStorage.setItem("session",true);
      })
      .catch(() => {
        window.sessionStorage.setItem("session",false);
      })
    }
  }

  UserRender = (props) => {
    const role = props.role;
        if (role === "superadmin") {
            return (
              <Redirect to="/admin/dashboard"/>
            )
        } 
        else if (role === "regional_admin") {
            return (
              <Redirect to="/taluk/dashboard"/>
            )
        } 
        else if (role === "area_admin") {
            return (
              <Redirect to="/village/dashboard"/>
            )
        }
        else if (role === "normal_user") {
            return (
              <Redirect to="/user/dashboard"/>
            )
        }
        else{
            return (
                <a className="nav-link" href="/">Guest</a>
            )
        }
}



  render(){
    return (
      <Router>
        <Switch> 

          {/**********************************************************
                              Global Path
          ************************************************************/}
          <Route path="/" exact>
                {
                  this.state.login
                  ?
                    <this.UserRender role={this.state.role}/>            
                  :
                    <div>
                      <Nav/>
                      <Landing/>
                    </div>
                }
          </Route>

          <Route path="/map" exact>
            <Map/>
          </Route>

            
          {/***********************************************************
                              Volunteer Path
          ************************************************************/}
            <Route path="/volunteers/search" exact>
                <Nav/>  
                <SearchVolunteer/>
            </Route>
            

            <Route path="/volunteers/edit/:id" >
              <Nav/>
              <EditVolunteer />
            </Route>
                
            

            <Route path="/volunteers/registration" exact>
                <Nav/>
                <VolunteerRegistraion/>
            </Route>

            <Route path="/volunteers/upload" exact>
                <Nav/>  
                <UploadVolunteer/>
            </Route>
            
          {/**********************************************************
                              Institution Path
          ************************************************************/}
            <Route path="/institutions/registration" exact>
                <Nav/>
                <InstitutionalRegistraion/>
            </Route>

            <Route path="/institutions/search" exact>
                <Nav/>  
                <SearchInstitution/>
            </Route>

            <Route path="/institutions/upload" exact>
                <Nav/>  
                <UploadInstitution/>
            </Route>

            {/**********************************************************
                              Password Path
           ************************************************************/}

            <Route path="/password/new" exact>
              <Nav/>
              <NewPassword/>
            </Route>

            <Route path="/password/change" exact>
              <Nav/>
              <ChangePassword/>
            </Route>

            <Route path="/password/forgot" exact>
              <Nav/>
              <ForgotPassword/>
            </Route>

            <Route path="/password/set" exact>
              <Nav/>
              <SetNewPassword/>
            </Route>

            
            
            

            <Route path="/login" exact>
                {
                  this.state.login
                  ?
                    <this.UserRender role={this.state.role}/>            
                  :
                    <div>
                      <Nav/>
                      <Login/>
                    </div>
                }
            </Route>
          {/**********************************************************
                             superadmin Path
           ************************************************************/}
            <Route path="/admin/dashboard" exact>
              <Nav/>
              <Dashboard/>
            </Route>

            
            <Route path="/admin/invite/taluk" exact>
              <Nav/>
              <InviteTalukAdmin/>
            </Route>
            <Route path="/admin/invite/village" exact>
              <Nav/>
              <InviteVillageAdmin/>
            </Route>

            <Route path="/admin/invite/user" exact>
              <Nav/>
              <InviteNormalUser/>
            </Route>

            <Route path="/admin/control/resource" exact>
              <Nav/>
              <ResourceAdminControl/>
            </Route>

            <Route path="/admin/control/police-station" exact>
              <Nav/>
              <PoliceStationAdminControl/>
            </Route>

            <Route path="/admin/edit" exact>
              <Nav/>
              <EditUser/>
            </Route>

          {/**********************************************************
                             RegionalAdmin Path
           ************************************************************/}
            <Route path="/taluk/dashboard" exact>
                <Nav/>
                <RegionalDashboard/>
            </Route>

            <Route path="/taluk/invite/village" exact>
                <Nav/>
                <RegionalInviteVillageAdmin/>
            </Route>
            <Route path="/taluk/invite/user" exact>
              <Nav/>
              <InviteNormalUser/>
            </Route>

            {/**********************************************************
                             village admin Path
           ************************************************************/}

            <Route path="/village/dashboard" exact>
                <Nav/>
                <AreaDashboard/>
            </Route>
            
            <Route path="/village/invite/user" exact>
              <Nav/>
              <InviteNormalUser/>
            </Route>

            {/**********************************************************
                             User Path
           ************************************************************/}
           <Route path="/user/dashboard" exact>
                <Nav/>
                <UserDashboard/>
            </Route>

          {/**********************************************************
                              404 path
          ************************************************************/}
            <Route>
                <Nav/>
                <NotFound/>
            </Route>

          </Switch>
      </Router>
  
    );
  }
}

