import React, {Component} from "react";
import InstitutionCard from "./InstitutionCard";
import {
  getTaluks,
  getVillage,
  getPanchayat,
  searchInstitution,
  institutionType,
  getInstitutionInitialData,
  getPoliceStation,
} from "../../url";
import axios from "axios";

export default class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      by_name: "",
      villageList: [],
      talukList: [],
      panchayatList: [],
      resourceList: [],
      villageListFiltred: [],
      institutionList: [],
      userData: [],
      policeStationList: [],

      loading: null,
      error: null,
      dataNotFound: null,

      by_type: "",
      taluk: "",
      region: "",
      resource: "",
      panchayat: "",
      policeStation: "",

      login: window.localStorage.getItem("loggedin"),
      uid: window.localStorage.getItem("uid"),
      client: window.localStorage.getItem("client"),
      "access-token": window.localStorage.getItem("token"),
    };

    this.handleChange = this.handleChange.bind(this);
    this.getInitailData = this.getInitailData.bind(this);
    this.handleTalukChange = this.handleTalukChange.bind(this);
    this.handleVillageChange = this.handleVillageChange.bind(this);
    this.handlePanchayatChange = this.handlePanchayatChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handlePoliceStationChange = this.handlePoliceStationChange.bind(this);
    this.getInstitutionType = this.getInstitutionType.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      loading: true,
    });

    this.getInitailData();
    this.getVillage();
    this.getTaluk();
    this.getPanchayat();
    this.getInstitutionType();
    this.getPoliceStation();
  }

  getInitailData() {
    axios
      .get(getInstitutionInitialData, {
        headers: {
          uid: this.state.uid,
          client: this.state.client,
          "Access-Token": this.state["access-token"],
        },
      })
      .then((response) => {
        const user = response.data;
        if (user.length === 0) {
          this.setState({
            ...this.state,
            userData: [...user],
            loading: false,
            dataNotFound: "Data not Found",
          });
        } else {
          this.setState({
            ...this.state,
            userData: [...user],
            loading: false,
            dataNotFound: null,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: "Something went wrong",
        });
      });
  }

  getVillage() {
    axios
      .get(getVillage)
      .then((response) => {
        const villages = response.data;
        this.setState({
          ...this.state,
          villageList: [...villages],
        });
      })
      .catch((error) => {});
  }

  getTaluk() {
    axios
      .get(getTaluks)
      .then((response) => {
        const taluks = response.data;

        this.setState({
          ...this.state,
          talukList: [...taluks],
        });
      })
      .catch((error) => {});
  }

  getPanchayat() {
    axios
      .get(getPanchayat)
      .then((response) => {
        const panchayat = response.data;

        this.setState({
          ...this.state,
          panchayatList: [...panchayat],
        });
      })
      .catch((error) => {});
  }

  getInstitutionType() {
    axios
      .get(institutionType)
      .then((response) => {
        var institution = Object.keys(response.data.institution_types);

        this.setState({
          ...this.state,
          institutionList: [...institution],
        });
      })
      .catch((error) => {});
  }

  getPoliceStation() {
    axios
      .get(getPoliceStation)
      .then((response) => {
        var policeStation = response.data;

        this.setState({
          policeStationList: [...policeStation],
        });
      })
      .catch((error) => {});
  }

  search(searchTerm, villageId, panchayatId, type, policeStation) {
    axios
      .get(searchInstitution, {
        params: {
          by_name: searchTerm,
          by_village: villageId,
          by_panchayath: panchayatId,
          by_type: type,
          by_police_station: policeStation,
        },
        headers: {
          uid: this.state.uid,
          client: this.state.client,
          "Access-Token": this.state["access-token"],
        },
      })
      .then((response) => {
        const user = response.data;
        if (user.length === 0) {
          this.setState({
            ...this.state,
            userData: [...user],
            loading: false,
            dataNotFound: "Data not Found",
          });
        } else {
          this.setState({
            ...this.state,
            userData: [...user],
            loading: false,
            dataNotFound: null,
          });
        }
      })

      .catch((error) => {
        this.setState({
          loading: false,
          error: "Something went wrong",
        });
      });
  }

  handleChange(event) {
    event.preventDefault();
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
      dataNotFound: null,
    });
    this.search(
      event.target.value,
      this.state.region,
      this.state.panchayat,
      this.state.by_type,
      this.policeStation
    );
  }

  handleVillageChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });

    this.search(
      this.state.by_name,
      event.target.value,
      "",
      this.state.by_type,
      this.policeStation
    );
  }

  handlePanchayatChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
    this.search(
      this.state.by_name,
      "",
      event.target.value,
      this.state.by_type,
      this.policeStation
    );
  }

  handlePoliceStationChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
    this.search(
      this.state.by_name,
      this.state.region,
      this.state.panchayat,
      this.state.by_type,
      event.target.value
    );
  }

  handleTypeChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });

    this.search(
      this.state.by_name,
      this.state.region,
      this.state.panchayat,
      event.target.value,
      this.policeStation
    );
  }

  handleTalukChange(event) {
    this.setState((prevState) => ({
      [event.target.name]: event.target.value,
      villageListFiltred: prevState.villageList.filter(
        (village) => village["taluk_id"] === event.target.value
      ),
    }));
  }

  handleReset() {
    this.setState({
      ...this.state,
      taluk: "",
      region: "",
      by_type: "",
      panchayat: "",
      policeStation: "",
      error: null,
    });

    this.search(this.state.by_name);
  }

  render() {
    return (
      <div className="container main-wrapper">
        {this.state.login ? (
          <div className="row">
            <div className="col-md-12">
              <h2 className="heading text-left">
                Search <span className="user">Institution</span>
              </h2>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <input
                className="search__input"
                type="text"
                name="by_name"
                value={this.state.by_name}
                placeholder="Search"
                onChange={this.handleChange}
              ></input>
            </div>
            <div className="col-md-4"></div>
            {/* *********************************************************************************
                                                        Filter
                            ***********************************************************************************/}

            <div className="col-md-12">
              <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <span className="navbar-brand" id="filter-title">
                  Filters
                </span>

                <span className="navbar-brand" id="filter-title">
                  <div className="container" onClick={this.handleReset}>
                    <button className="reset-btn" href="#">
                      Reset Filter
                    </button>
                  </div>
                </span>

                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNavAltMarkup2"
                  aria-controls="navbarNavAltMarkup"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div
                  className="collapse navbar-collapse justify-content-center"
                  id="navbarNavAltMarkup2"
                >
                  <form className="filter">
                    {/* *********************************************************************************
                                                                            Police Station
                                            ***********************************************************************************/}
                    {this.state.taluk ||
                    this.state.panchayat ||
                    this.state.region ? (
                      <div className="form-field disabled">
                        <input
                          type="text"
                          placeholder=" "
                          autofill="off"
                          required
                          disabled={true}
                          className="disabled"
                        />
                        <span className="label disabled">
                          Select Police Station
                        </span>
                      </div>
                    ) : (
                      <div className="form-field">
                        <select
                          type=""
                          name="policeStation"
                          placeholder=" "
                          autofill="off"
                          required
                          value={this.state.policeStation}
                          onChange={this.handlePoliceStationChange}
                        >
                          <option value={""} disabled={true}>
                            Select Police Station
                          </option>

                          {this.state.policeStationList.map((station) => (
                            <option key={station.id} value={station.id}>
                              {station.name}
                            </option>
                          ))}
                        </select>

                        <span className="select-label">Police Station</span>
                      </div>
                    )}
                    {/* *********************************************************************************
                                                                            Type
                                            ***********************************************************************************/}
                    <div className="form-field">
                      <select
                        type=""
                        name="by_type"
                        placeholder=" "
                        autofill="off"
                        required
                        value={this.state.by_type}
                        onChange={this.handleTypeChange}
                      >
                        <option value={""} disabled={true}>
                          Select Resource Type
                        </option>

                        {this.state.institutionList.map((authority, index) => (
                          <option key={index} value={index}>
                            {authority}
                          </option>
                        ))}
                      </select>

                      <span className="select-label">Resource Type</span>
                    </div>
                    {/* *********************************************************************************
                                                                            Taluk
                                            ***********************************************************************************/}
                    {this.state.policeStation ? (
                      <div className="form-field disabled">
                        <input
                          type="text"
                          placeholder=" "
                          autofill="off"
                          required
                          disabled={true}
                          className="disabled"
                        />
                        <span className="label disabled">Select Taluk</span>
                      </div>
                    ) : (
                      <div>
                        {this.state.panchayat ? (
                          <div className="form-field disabled">
                            <input
                              type="text"
                              placeholder=" "
                              autofill="off"
                              required
                              disabled={true}
                              className="disabled"
                            />
                            <span className="label disabled">Select Taluk</span>
                          </div>
                        ) : (
                          <div className="form-field">
                            <select
                              type=""
                              name="taluk"
                              placeholder=" "
                              autofill="off"
                              required
                              value={this.state.taluk}
                              onChange={this.handleTalukChange}
                            >
                              <option value={""} disabled={true}>
                                Select Taluk
                              </option>

                              {this.state.talukList.map((region) => (
                                <option key={region.id} value={region.id}>
                                  {region.name}
                                </option>
                              ))}
                            </select>
                            <span className="select-label">Taluk</span>
                          </div>
                        )}
                      </div>
                    )}

                    {/* *********************************************************************************
                                                                            Village
                                            ***********************************************************************************/}
                    {this.state.policeStation ? (
                      <div className="form-field disabled">
                        <input
                          type="text"
                          placeholder=" "
                          autofill="off"
                          required
                          disabled={true}
                          className="disabled"
                        />
                        <span className="label disabled">Select Village</span>
                      </div>
                    ) : (
                      <div>
                        {this.state.taluk ? (
                          <div className="form-field">
                            <select
                              type=""
                              name="region"
                              placeholder=" "
                              autofill="off"
                              required
                              value={this.state.region}
                              onChange={this.handleVillageChange}
                            >
                              <option value={""} disabled={true}>
                                Select Village
                              </option>

                              {this.state.villageListFiltred.map((region) => (
                                <option key={region.id} value={region.id}>
                                  {region.name}
                                </option>
                              ))}
                            </select>

                            <span className="select-label">Village</span>
                          </div>
                        ) : (
                          <div className="form-field disabled">
                            <input
                              type="text"
                              placeholder=" "
                              autofill="off"
                              required
                              disabled={true}
                              className="disabled"
                            />
                            <span className="label disabled">
                              Select Village
                            </span>
                          </div>
                        )}
                      </div>
                    )}

                    {/* *********************************************************************************
                                                                            panchayat
                                            ***********************************************************************************/}
                    {this.state.policeStation ? (
                      <div className="form-field disabled">
                        <input
                          type="text"
                          placeholder=" "
                          autofill="off"
                          required
                          disabled={true}
                          className="disabled"
                        />
                        <span className="label disabled">Select Panchayat</span>
                      </div>
                    ) : (
                      <div>
                        {this.state.taluk ? (
                          <div className="form-field disabled">
                            <input
                              type="text"
                              placeholder=" "
                              autofill="off"
                              required
                              disabled={true}
                              className="disabled"
                            />
                            <span className="label disabled">
                              Select Panchayat
                            </span>
                          </div>
                        ) : (
                          <div className="form-field">
                            <select
                              type=""
                              name="panchayat"
                              placeholder=" "
                              autofill="off"
                              required
                              value={this.state.panchayat}
                              onChange={this.handlePanchayatChange}
                            >
                              <option value={""} disabled={true}>
                                Select Panchayat
                              </option>

                              {this.state.panchayatList.map((region) => (
                                <option key={region.id} value={region.id}>
                                  {region.name}
                                </option>
                              ))}
                            </select>

                            <span className="select-label">Panchayat</span>
                          </div>
                        )}
                      </div>
                    )}
                  </form>
                </div>
              </nav>
            </div>

            {this.state.loading ? (
              <div className="col-md-12 text-center">
                <div className="spinner-grow text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            {this.state.error ? (
              <div className="col-md-12">
                <div className="alert alert-danger" role="alert">
                  {this.state.error}
                </div>
              </div>
            ) : (
              <div></div>
            )}
            {this.state.dataNotFound ? (
              <div className="col-md-12">
                <div className="alert alert-primary" role="alert">
                  {this.state.dataNotFound}
                </div>
              </div>
            ) : (
              <div></div>
            )}

            {this.state.userData.map((data) => (
              <div key={data.id} className="col-md-4">
                <InstitutionCard
                  data={data}
                  village={this.state.villageList.find(
                    (village) => village.id === data.village_id
                  )}
                  taluk={
                    this.state.villageList.find(
                      (village) => village.id === data.village_id
                    )
                      ? this.state.talukList.find(
                          (taluk) =>
                            taluk.id ===
                            this.state.villageList.find(
                              (village) => village.id === data.village_id
                            ).taluk_id
                        )
                      : null
                  }
                ></InstitutionCard>
              </div>
            ))}
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12">
              <div className="alert alert-primary" role="alert">
                Sorry this page is not accessible to you try logging in.
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
