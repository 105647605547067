import React,{Component} from 'react'
import axios from 'axios'
import {
    institutionType,
    authorityType,
    getTaluks,
    getVillage,
    getPanchayat,
    institutionRegistration,
    getPoliceStation
} from '../../url'

export default class InstitutionalRegistration extends Component{
    constructor(props){
        super(props)

        this.state = {
            name: "",
            institution_type:"",
            address: "",
            phone1: "",
            phone2: "",
            authority_type: "",
            region: "",
            panchayat:"",
            pincode:"",
            email:"",
            policeStation:'',

            loading:null,
            info:null,
            error:null,

            taluk:'',
            authority:[],
            institution:[],
            talukList:[],
            panchayatList:[],
            villageList:[],
            policeStationList:[],
            villageListFiltred:[],
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTalukChange = this.handleTalukChange.bind(this);
    }

    componentDidMount(){
        axios.get(institutionType)
        .then(response => {
            var institution = Object.keys(response.data.institution_types)
            this.setState({
                institution:[...institution]
            })

        })
        .catch(error => {
             
        })

        axios.get(authorityType)
        .then(response => {
            var authority = Object.keys(response.data.authority_types)
            this.setState({
                authority:[...authority]
            })

        })
        .catch(error => {
             
        })

        axios.get(getTaluks)
        .then(response => {
            var taluk = response.data

            this.setState({
                talukList:[...taluk]
            })
        })
        .catch(error => {
             
        })

        axios.get(getPanchayat)
        .then(response => {

            var panchayat = response.data

            this.setState({
                panchayatList:[...panchayat]
            })
        })
        .catch(error => {
             
        })

        axios.get(getVillage)
        .then(response => {
            var villages = response.data

            this.setState({
                villageList:[...villages]
            })
        })
        .catch(error => {
             
        })
        axios.get(getPoliceStation)
        .then(response => {
            var policeStation = response.data

            this.setState({
                policeStationList:[...policeStation]
            })
        })
        .catch(error => {
             
        })
        
    }

    handleChange(event) {

        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
        });
    }

    handleTalukChange(event){
        this.setState(prevState => ({
            [event.target.name]: event.target.value,
            villageListFiltred : prevState.villageList.filter(village => village["taluk_id"] === event.target.value),
            region:""
        }))
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({
            ...this.state,
            loading:true,
            error:null,
            info:null,
        })

        axios.post(institutionRegistration,{
            "institution": {
                "name": this.state.name,
                "institution_type":this.state.institution_type,
                "address": this.state.address,
                "phone1": this.state.phone1,
                "phone2": this.state.phone2,
                "authority_type": this.state.authority_type,
                "village_id": this.state.region,
                "panchayath_id": this.state.panchayat,
                "police_station_id":this.state.policeStation,
                "pincode": this.state.pincode,
                "email":this.state.email
            }
        })
        .then(response => {
            this.setState({
                ...this.state,
                loading:false,
                info:'Thank you for Registering',

                name:"",
                institution_type:"",
                address:"",
                phone1:"",
                phone2:"",
                authority_type:"",
                taluk:"",
                region:"",
                panchayat:"",
                village_id:"",
                panchayath_id:"",
                pincode:"",
                email:"",

            })

            setTimeout(function(){
                this.setState({
                    info:null,
                    error:null,
                    loading:false,
                })
            }.bind(this),3000)

            window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
        })
        .catch(error => {
             
            this.setState({
                ...this.state,
                loading:false,
                error: "Something went wrong"
            })   

            window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
        })
    }


    render(){
        return(
            <div className="container main-wrapper">
                <div className="row">
                <div className="col-md-12">
                    <h2 className="heading text-center">Institution<span className="user"> Registration</span></h2>
                </div>
                <div className="col-md-3">
                </div>
                    <div className="col-md-6">
                    {
                        this.state.error
                        ?
                            <div className="alert alert-danger">
                                 {this.state.error}   
                            </div>   
                        :
                            
                            <div></div>
                    }
                    
                    {
                        this.state.info
                        ?
                            <div className="alert alert-primary">
                                {this.state.info}
                            </div>
                        :
                            
                             <div></div>
                    }
                    <svg xmlns="http://www.w3.org/2000/svg" height="50px" viewBox="0 0 24 24" width="60px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"/></svg>
                        <form onSubmit={this.handleSubmit} className="form secondary-form">

                            <div className="form-field">
                                <input type="text" name='name' placeholder=" " autofill="off" required 
                                value={this.state.name} onChange={this.handleChange}/>
                                <span className="label">Name of Institute<span className="formrequired">*</span></span>
                            </div>

                            <div className="form-field">
                                <select type="" name='institution_type' placeholder=" " autofill="off" required 
                                value={this.state.institution_type} onChange={this.handleChange}>
                                    <option value={""} disabled={true}>Select Institution</option>

                                    {
                                        this.state.institution.map((institution,index) => (
                                            <option  key={index} value={institution}>{institution}</option>
                                        ))
                                    }
                              
                            
                                </select>
                                
                                <span className="select-label" >Institution Type<span className="formrequired">*</span></span>
                            </div>

                            <div className="form-field">
                                <input type="tel" name='phone1' placeholder=" " autofill="off" required 
                                value={this.state.phone1} onChange={this.handleChange} pattern={"[1-9]{1}[0-9]{9}"}/>
                                <span className="label">Phone No. 1<span className="formrequired">*</span></span>
                            </div>

                            <div className="form-field">
                                <input type="tel" name='phone2' placeholder=" " autofill="off" 
                                value={this.state.phone2} onChange={this.handleChange} pattern={"[1-9]{1}[0-9]{9}"}/>
                                <span className="label">Phone No. 2</span>
                            </div>

                            <div className="form-field">
                                <input type="email" name='email' placeholder=" " autofill="off" required 
                                value={this.state.email} onChange={this.handleChange}/>
                                <span className="label">Email<span className="formrequired">*</span></span>
                            </div>

                            <div className="form-field">
                                <textarea type="textarea" name='address' placeholder=" " autofill="off" required 
                                value={this.state.address} onChange={this.handleChange} rows={3}/>
                                <span className="label">Address <span className="formrequired">*</span></span>
                            </div>

                            <div className="form-field">
                                <select type="" name='authority_type' placeholder=" " autofill="off" required 
                                value={this.state.authority_type} onChange={this.handleChange}>
                                    <option value={""} disabled={true}>Select Authority</option>

                                    {
                                        this.state.authority.map((authority,index) => (
                                            <option  key={index} value={authority}>{authority}</option>
                                        ))
                                    }

                                </select>
                                
                                <span className="select-label" >Authority Type<span className="formrequired">*</span></span>
                            </div>
                            
                            {/* *********************************************************************************
                                                            Taluk
                            ***********************************************************************************/}

                            <div className="form-field">
                                <select type="" name='taluk' placeholder=" " autofill="off" required 
                                value={this.state.taluk} onChange={this.handleTalukChange}>
                                    <option value={""} disabled={true}>Select Taluk</option>

                                    {
                                        this.state.talukList.map(region => (
                                            <option  key={region.id} value={region.id}>{region.name}</option>
                                        ))
                                    }
                              
                            
                                </select>
                                
                                <span className="select-label" >Taluk <span className="formrequired">*</span></span>
                            </div>


                            {/* *********************************************************************************
                                                            Village
                            ***********************************************************************************/}
                            <div className="form-field">
                                <select type="" name='region' placeholder=" " autofill="off" required 
                                value={this.state.region} onChange={this.handleChange}>
                                    <option value={""} disabled={true}>Select Village</option>

                                    {
                                        this.state.villageListFiltred.map(region => (
                                            <option  key={region.id} value={region.id}>{region.name}</option>
                                        ))
                                    }
                              
                            
                                </select>
                                
                                <span className="select-label" >Village<span className="formrequired">*</span></span>
                            </div>
                            {/* *********************************************************************************
                                                            Panchayat
                            ***********************************************************************************/}
                            <div className="form-field">
                                <select type="" name='panchayat' placeholder=" " autofill="off" required 
                                value={this.state.panchayat} onChange={this.handleChange}>
                                    <option value={""} disabled={true}>Select Panchayat</option>

                                    {
                                        this.state.panchayatList.map(region => (
                                            <option  key={region.id} value={region.id}>{region.name}</option>
                                        ))
                                    }
                              
                            
                                </select>
                                
                                <span className="select-label" >Panchayat<span className="formrequired">*</span></span>
                            </div>
                            {/* *********************************************************************************
                                                            Police Station
                            ***********************************************************************************/}
                            <div className="form-field">
                                <select type="" name='policeStation' placeholder=" " autofill="off" required 
                                value={this.state.policeStation} onChange={this.handleChange}>
                                    <option value={""} disabled={true}>Select Police Station</option>

                                    {
                                        this.state.policeStationList.map(station => (
                                            <option  key={station.id} value={station.id}>{station.name}</option>
                                        ))
                                    }
                              
                            
                                </select>
                                
                                <span className="select-label" >Police Station<span className="formrequired">*</span></span>
                            </div>
                            {/* *********************************************************************************
                                                            Pincode
                            ***********************************************************************************/}
                            <div className="form-field">
                                <input type="tel" name='pincode' placeholder=" " autofill="off" required 
                                value={this.state.pincode} onChange={this.handleChange} pattern={"[1-9]{1}[0-9]{5}"}/>
                                <span className="label">Pincode<span className="formrequired">*</span></span>
                            </div>


                            {
                                this.state.loading
                                ?
                                <div className="container">
                                    <div className="loader-button">
                                        <div className="spinner-border text-light" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="container">
                                    <button className="submit-btn" href="#">Register</button>
                                </div>
                            }
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}