import React,{Component} from 'react';
import loginImage from '../images/loginImage.webp';
import axios from 'axios';
import {signIn} from '../url';

export default class Login extends Component{
    constructor(props) {
        super(props);

        this.state = {
            email:null,
            password:null,
            success: true,
            error:"",
            showPassword:false,
            info:window.localStorage.getItem("info"),
            loading:false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {

        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
        });

    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({
            ...this.state,
            loading:true,
            info:null,
            error:null
        })
       
        axios.post(signIn,this.state)
        .then((response)=>{
                const result = response["data"];
                const headers = response["headers"]
                const store = result["data"];
                
                window.localStorage.setItem("id",store["id"]);
                window.localStorage.setItem("email",store["email"]);
                window.localStorage.setItem("uid",store["uid"]);
                window.localStorage.setItem("role",store["role"]);
                window.localStorage.setItem("loggedin",true);
                window.localStorage.setItem("token",headers["access-token"]);
                window.localStorage.setItem("client",headers["client"]);
                window.sessionStorage.setItem("session",true);
                if(store.role === "superadmin")
                {
                    window.localStorage.setItem("iap","0b9x3JGsD0uBvV1zA+vktQ==");
                    window.location='/volunteers/search'
                }
                else if(store.role === "regional_admin")
                {
                    window.localStorage.setItem("iap","y9HUXikv2ka8s7/naBPysg==");
                    window.location='/volunteers/search'
                }
                else if(store.role === "area_admin")
                {
                    window.localStorage.setItem("iap","qf3wBr83NUarUn/UyNbyjQ==");
                    window.location='/volunteers/search'
                }
                else if(store.role === "normal_user")
                {
                    window.localStorage.setItem("iap","hToyr+zlf0Sm7KV5iz8OtA==");
                    window.location='/volunteers/search'
                }
                else
                {
                    window.location='/'
                }
        })
        .catch(err => {
            if (err.response) {
              this.setState({
                  ...this.state,
                  loading:false,
                  success:false,
                  error: "Invalid email or password"
              })
            } else if (err.request) {
                this.setState({
                    ...this.state,
                    loading:false,
                    success:false,
                    error: "Something went wrong"
                })
            } 
        })

    }

    render(){
        return(
            <div className="container main-wrapper">
                <div className="row">
                    <div className="col-md-12 col-lg-7">
                        <img src={loginImage} className="heroImage" alt={"Reach Admin Login"}/>
                    </div>

                    <div className="col-md-12 col-lg-5">
                    {
                        this.state.success
                        ?
                            <div></div>
                        :
                            <div className="alert alert-danger">
                                {this.state.error}
                            </div>
                    }
                    {
                        this.state.info
                        ?
                            <div className="alert alert-primary">
                                {this.state.info}
                            </div>
                        :
                            
                             <div></div>
                    }


                        <form onSubmit={this.handleSubmit} className="form">

                            <div className="form-field">
                                <input type="email" name='email' placeholder=" " autofill="off" required 
                                value={this.state.email || ""} onChange={this.handleChange}/>
                                <span className="label">Email</span>
                            </div>

                            <div className="form-field">
                                <input type={this.state.showPassword? "text" : "password"} name='password' placeholder=" " autofill="off" required 
                                value={this.state.password || ""} onChange={this.handleChange}/>
                                <span className="label" autofill="off">Password</span>
                                <img onClick={()=>{this.setState({...this.state, showPassword: !this.state.showPassword})}} className="eye_password" src={this.state.showPassword? "https://media.geeksforgeeks.org/wp-content/uploads/20210917150049/eyeslash.png" : "https://media.geeksforgeeks.org/wp-content/uploads/20210917145551/eye.png"} alt="eye"/>

                            </div>

                            <div className="forgot text-right">
                                <a href="/password/forgot">Forgot Password</a>
                            </div>
                            {
                                this.state.loading
                                ?

                                
                                <div className="container">
                                    <div className="loader-button">
                                        <div className="spinner-border text-light" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="container">
                                    <button className="submit-btn" href="#">Login</button>
                                </div>
                            }
                            

                        </form>

                    </div>

                </div>

            </div>
        );
    }
}


