import React,{Component} from 'react';
import axios from 'axios';
import { inviteAdmin } from '../url';
import queryString from 'query-string';

export default class NewPassword extends Component{
    constructor(props) {
        super(props);

        this.state = {
            password:'',
            password_confirmation:'',
            error:null,
            info:null,
            loading:false,
            showPassword:false,
            showPasswordConfirmation:false,
        };

        this.headerDetails = queryString.parse(window.location.search)

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {

        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
        });

    }


    handleSubmit(event) {
        event.preventDefault();

        this.setState({
            ...this.state,
            error:null,
            loading:true,
            info:null
        })
       
        if(this.state.password === this.state.password_confirmation)
        {
            axios.put(inviteAdmin,
                {
                    invitation_token:this.headerDetails["invitation_token"],
                    password: this.state.password,
                    password_confirmation: this.state.password_confirmation,
                }
            )
            .then((response) => {
                this.setState({
                    ...this.state,
                    loading:false,
                    info:"Password changed"
                })

                setTimeout(function(){
                    this.setState({
                        ...this.state,
                        loading:false,
                        info:null
                    })
                    window.location='/login'
                }.bind(this),2000)
               
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({
                        ...this.state,
                        loading:false,
                        error: "Something went wrong"
                    })
                    this.setState({
                        ...this.state,
                        loading:false,
                        info:null
                    })

                  } else if (error.request) {
                      this.setState({
                          ...this.state,
                          loading:false,
                          error: "Something went wrong"
                      })
                      this.setState({
                        ...this.state,
                        loading:false,
                        info:null
                    })
                  } 
            })  
        }
        else
        {
            this.setState({
                ...this.state,
                error:'Passwords do not match',
                loading:false
            })
        }
    }
    
    render(){
        return(
            
            <div className="container main-wrapper">
                
                <div className="row">
                <div className="col-md-3">
                </div>
                    <div className="col-md-6">
                    {
                        this.state.error
                        ?
                            <div className="alert alert-danger">
                                 {this.state.error}   
                            </div>   
                        :
                            
                            <div></div>
                    }
                    {
                        this.state.info
                        ?
                            <div className="alert alert-primary">
                                 {this.state.info}   
                            </div>   
                        :
                            
                            <div></div>
                    }
                    <svg xmlns="http://www.w3.org/2000/svg" height="60px" viewBox="0 0 24 24" width="60px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"/></svg>
                        <form onSubmit={this.handleSubmit} className="form secondary-form">

                            <div className="form-field">
                                <input type={this.state.showPassword? "text" : "password"}  name='password' placeholder=" " autofill="off" required 
                                value={this.state.password} onChange={this.handleChange}/>
                                <span className="label">New Password</span>
                                <img onClick={()=>{this.setState({...this.state, showPassword: !this.state.showPassword})}} className="eye_password" src={this.state.showPassword? "https://media.geeksforgeeks.org/wp-content/uploads/20210917150049/eyeslash.png" : "https://media.geeksforgeeks.org/wp-content/uploads/20210917145551/eye.png"} alt="eye"/>
                            </div>
                            <div className="form-field">
                                <input type={this.state.showPasswordConfirmation? "text" : "password"} name='password_confirmation' placeholder=" " autofill="off" required 
                                value={this.state.password_confirmation} onChange={this.handleChange}/>
                                <span className="label">Confirm Password</span>
                                <img onClick={()=>{this.setState({...this.state, showPasswordConfirmation: !this.state.showPasswordConfirmation})}} className="eye_password" src={this.state.showPasswordConfirmation? "https://media.geeksforgeeks.org/wp-content/uploads/20210917150049/eyeslash.png" : "https://media.geeksforgeeks.org/wp-content/uploads/20210917145551/eye.png"} alt="eye"/>
                            </div>

                            {
                                this.state.loading
                                ?
                                <div className="container">
                                    <div className="loader-button">
                                        <div className="spinner-border text-light" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="container">
                                    <button className="submit-btn" href="#">Reset Password</button>
                                </div>
                            }
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}


