import React,{Component} from 'react';
import heroImage from '../images/heroImage.webp'
import saakhi from '../images/saakhi.jpg'
import mcc from '../images/mcc.png'
import traumaCare from '../images/traumacarelogo.png'

export default class Landing extends Component{
    render(){
        return(
            <div>
                <div className="container text-center main-wrapper">

                    <div className="row">

                        <div className="col-lg-4 text-container ">
                            <h2 className="title">Trauma Reach</h2>
                            <p className="tagline">Developed by: Saakhi</p>
                        </div>

                        <div className="col-lg-8 img-container">
                            <img src={heroImage} className="heroImage heroImageSpace" alt={"Reach Helping Hand"} />
                        </div>

                    </div>
                </div>
                <div className="container main-wrapper">

                    <div className="row">
                        <div className="col-lg-12 about">
                            <h3>
                                Trauma Resource Aggregation in Critical Hours (Reach)
                            </h3>
                        </div>

                        <div className="col-lg-6">
                        From the lessons learnt and inspired from <strong>Kerala Floods 2018</strong>, we wanted to develop a technology- based solution to bridge the gap between the demand and the available resources during the times of disaster relief and management. 
                        <strong>The Resource Aggregation in Critical Hours(REACH)</strong>, disaster management application has been developed with an aim to identify,locate,communicate, share and contribute all resources, including human power and materials.
                        The system consists of 4 set of admins- Super Admin, Taluk Admin, Village Admin and Admin who mantain, control and approve users/institutions. 
                        The Super Admin under which there will be several regional, Admins which are divided on taluk bases. Under them there will be several Area Admins sorted on Panchayat basis. The Volunteers as well as the organizations will be under these area admins. 
                        </div>

                        <div className="col-lg-2"></div>
                        <div className="col-lg-4">
                            <h5>Salient features of the system</h5>
                            <ol>
                                <li>Access Control</li>                            
                                <li>Resource Management</li>
                                <li>Search Engine</li>
                                <li>Filter Mechanism</li>
                                <li>Cloud Database Server</li>
                            </ol>
                        </div>
                    </div>
                </div>

                <div className="container main-wrapper">

                    <div className="row about">
                        <div className="col-lg-4">
                            <h6 className='text-center'>Conceptualized & Developed By Saakhi</h6>
                            <a href="https://saakhi.org.in/about/"> <img src={saakhi} className='logos' alt={"Reach Helping Hand"} /> </a>
                        </div>
                        <div className="col-lg-4">
                            <h6 className='text-center'>Financed By MCC</h6>
                            <img src={mcc} className='logos' alt={"Reach Helping Hand"} />
                        </div>
                        <div className="col-lg-4">
                            <h6 className='text-center'>Operated By Trauma Care</h6>
                            <img src={traumaCare} className='logos' alt={"Reach Helping Hand"} />
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}